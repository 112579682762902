import * as actionTypes from "./actionTypes";
import {
  getAccountingImputationsAPI,
  deleteAccountingImputationsAPI,
  createAccountingImputationsAPI,
  modifyAccountingImputationsAPI,
  duplicateAccountingImputationsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe una imputación para el mismo concepto, tipo de imputación y asiento';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar la imputación porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getAccountingImputationsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAccountingImputationsAPI();

    let data = response.data && response.data.data;
    let entryList = response.data && response.data.entryList;
    let conceptList = response.data && response.data.conceptList;
    let imputationList = response.data && response.data.imputationList;
    let distributionList = response.data && response.data.distributionList;
    let conceptsWithoutImputation = response.data && response.data.conceptsWithoutImputation;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, entryList, conceptList, imputationList, distributionList, conceptsWithoutImputation },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteAccountingImputationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAccountingImputationsAPI(dataToSend);

    let data = response.data && response.data.data;
    let conceptsWithoutImputation = response.data && response.data.conceptsWithoutImputation;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data, conceptsWithoutImputation },
    });

    dispatch(openSnackbarAction({ msg: 'Imputación eliminada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createAccountingImputationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createAccountingImputationsAPI(dataToSend);

    let data = response.data && response.data.data;
    let conceptsWithoutImputation = response.data && response.data.conceptsWithoutImputation;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data, conceptsWithoutImputation },
    });

    dispatch(openSnackbarAction({ msg: 'Imputación creada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyAccountingImputationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyAccountingImputationsAPI(dataToSend);

    let data = response.data && response.data.data;
    let conceptsWithoutImputation = response.data && response.data.conceptsWithoutImputation;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data, conceptsWithoutImputation },
    });

    dispatch(openSnackbarAction({ msg: 'Imputación actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateAccountingImputationsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateAccountingImputationsAPI(dataToSend);

    let data = response.data && response.data.data;
    let conceptsWithoutImputation = response.data && response.data.conceptsWithoutImputation;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data, conceptsWithoutImputation },
    });

    dispatch(openSnackbarAction({ msg: 'Imputación duplicada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};
