import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getAlertsSettingsAPI,
  createAlertsSettingsAPI,
  modifyAlertsSettingsAPI,
  deleteAlertsSettingsAPI,
  duplicateAlertsSettingsAPI,
  getAlertActivacionSettingsAPI,
  modifyAlertActivacionSettingsAPI,
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch, alertType) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      if (alertType === "U") {
        errorString = "Ya existe una alerta o aviso con el mismo nombre y destinatario que ha ingresado";
      } else {
        errorString = "Ya existe una alerta o aviso con el mismo nombre que ha ingresado";
      }
      break;
    case "PROCESS_IN_USE":
      errorString = "El proceso especial ya está asociado a otra alerta o aviso y con el mismo modo de ejecución. No es posible asociarlo más de una vez con el mismo modo de ejecución.";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
}

export const getAlertsSettingsAction = (alertType) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getAlertsSettingsAPI(`?origen=${alertType}`);

    let rows = response.data && response.data.data;
    let claseList = response.data && response.data.claseList;
    let destinatarioList = response.data && response.data.destList;
    let groupsList = response.data && response.data.groupsList;
    let nivelList = response.data && response.data.nivelList;
    let selcolList = response.data && response.data.selcolList;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: {
        rows,
        nivelList,
        claseList,
        selcolList,
        destinatarioList,
        groupsList,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createAlertSettingsAction = (data, alertType) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createAlertsSettingsAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        rows,
      }
    });
    dispatch(openSnackbarAction({ msg: "Alerta/Aviso creado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch, alertType);
  }
};

export const modifyAlertSettingsAction = (data, alertType) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyAlertsSettingsAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: {
        rows,
      }
    });
    dispatch(openSnackbarAction({ msg: "Alerta/Aviso modificado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch, alertType);
  }
};

export const deleteAlertSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAlertsSettingsAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: {
        rows,
      }
    });
    dispatch(openSnackbarAction({ msg: "Alerta/Aviso eliminada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    errorHandler(e, dispatch);
  }
};

export const duplicateAlertSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateAlertsSettingsAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: {
        rows,
      }
    });
    dispatch(openSnackbarAction({ msg: "Alerta/Aviso duplicado con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getAlertActivationSettingsAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACTIVATION_ROWS });
  try {
    let response = await getAlertActivacionSettingsAPI(`?Tipo de Alerta o Aviso=${code}`);

    let activationRow = response.data && response.data.data;
    let activaList = response.data && response.data.activaList;
    let destinatarioList = response.data && response.data.destinatarioList;
    let exediasmList = response.data && response.data.exediasmList;
    let exediassList = response.data && response.data.exediassList;
    let exemmList = response.data && response.data.exemmList;
    let exemomList = response.data && response.data.exemomList;
    let notificationsList = response.data && response.data.notificationsList;
    let groupsList = response.data && response.data.groupsList;
    let roleList = response.data && response.data.roleList;
    let templateList = response.data && response.data.templateList;
    let processList = response.data && response.data.processLists;

    dispatch({
      type: actionTypes.GET_ACTIVATION_ROWS_FULFILLED,
      payload: {
        activationRow,
        activaList,
        destinatarioList,
        exediasmList,
        exediassList,
        exemmList,
        exemomList,
        notificationsList,
        groupsList,
        roleList,
        templateList,
        processList,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ACTIVATION_ROWS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyAlertActivationSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyAlertActivacionSettingsAPI(data);
    let rows = response.data && response.data.data;

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: {
        rows,
      }
    });
    dispatch(openSnackbarAction({ msg: "Activación de alerta/aviso modificada con éxito", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};