import paths from "@icarius-localization/paths";

export default [
    {
        pages: ['accessSettings/managersEmployers'],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['accessSettings/managersEmployers'],
        config: {
            headerName: 'Email',
            field: "Mail",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accessSettings/managersEmployers'],
        config: {
            headerName: 'Estado',
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accessSettings/managersEmployers'],
        config: {
            headerName: 'Personal de planta',
            field: "Personal de planta",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accessSettings/managersEmployers'],
        config: {
            headerName: 'Grupo',
            field: "Grupo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Apellidos y nombres (Managers/Empleadores)',
            field: "Apellidos y nombres (Managers/Empleadores)",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Email',
            field: "Email",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Status',
            field: "Status",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Rol',
            field: "Rol",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Grupos con acceso',
            field: "Grupos con acceso",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Administrador de ICARIUS',
            field: "Administrador de Icarius",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accessSettings],
        config: {
            headerName: 'Puede recibir solicitudes ?',
            field: "Puede recibir solicitudes",
            filter: "agSetColumnFilter",
        }
    },
]