import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createAlertsTemplatesAction, updateAlertsTemplatesAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, handleClose, isAdmin } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createAlertsTemplatesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateAlertsTemplatesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, isAdmin);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* name */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            onChange={(e) => setFormValue(e.target.value, "name")}
            value={formData.name}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}> {/* Texto */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Texto'}
            onChange={(e) => setFormValue(e.target.value, "text")}
            value={formData.text}
            multiline
            rows={10}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify={"flex-end"}
        alignItems="center"
        style={{ visibility: isAdmin ? 'visible' : 'hidden', paddingBottom: 20, paddingTop: 20 }}
      >
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;