import React, { useState } from "react";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { getAccessSettingsAction, deleteAccessSettingsAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const { data, users, groups, closeNew, rowsToUse, getShouldDisabledIfThereAreAdmins } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getDeleteQuestion(),
      onConfirm: handleDeletePublication,
    }));
  }

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteAccessSettingsAction({ code: data.code, email: data.email }))
        .then((res) => {
          if (res?.status === 200) {
            dispatch(getAccessSettingsAction());
          }
        });
    }
  }

  const handleClosePublication = () => {
    !isCreate && setIsOpen(prev => !prev);
  }

  const getGroupsText = (value) => {
    if (value.groups.length === 0 || value.groups.length === groups.length) {
      return "Todos los grupos de acceso";
    }
    return `${value.groups.length} grupo/s de acceso`
  }

  const getDeleteQuestion = () => {
    if (!data) return '¿Esta seguro que desea eliminar al usuario de la lista?'
    return getLocalizedString(data.employer === "N" ? "deleteManagerQuestion" : "deleteEmployerQuestion");
  }

  const getRolString = () => {
    if (data.employer !== 'Y') return 'Manager';
    if (data.isAdmin === 'Y') return 'Administrador'
    return 'Empleador'
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: !isCreate ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={`Creación de acceso`} />
                :
                <>
                  <DataContainer sm={12} md={6} lg={5} text={data.name} />
                  <DataContainer sm={12} md={6} lg={2} text={Boolean(data.active === "Y") ? "Activo" : "Inactivo"} />
                  <DataContainer sm={12} md={6} lg={2} text={getRolString()} />
                  <DataContainer sm={12} md={6} lg={3} text={getGroupsText(data)} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify="center">
            {
              !getShouldDisabledIfThereAreAdmins() &&
              <Tooltip title={getLocalizedString("delete")}>
                <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                  <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          users={users}
          groups={groups}
          rowsToUse={rowsToUse}
          getGroupsText={getGroupsText}
          handleClose={isCreate ? closeNew : handleClosePublication}
          getShouldDisabledIfThereAreAdmins={getShouldDisabledIfThereAreAdmins}
        />
      }
    </>
  );
}

export default DataItem;
