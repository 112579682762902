import { useState } from "react";
import * as yup from 'yup';

const useHandleFormDuplicate = (data, alertType, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "Grupos de destinatarios") {
        return [];
      }

      return "";
    }

    const fieldNames = [
      'Nombre',
      'Destinatario',
      'Emails específicos',
      'Grupos de destinatarios',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const systemSchema = yup.object().shape({
      'Nombre': yup.string().max(254).required(),
    });

    const userSchema = yup.object().shape({
      'Nombre': yup.string().max(254).required(),
      'Destinatario': yup.string().max(254).required(),
      'Emails específicos': yup.string().max(254)
        .when('Destinatario', {
          is: (value) => ['CE', 'ME', 'EE'].includes(value),
          then: yup.string().max(254).required()
        }),
      'Grupos de destinatarios': yup.array().of(yup.string())
        .when('Destinatario', {
          is: (value) => ['GR'].includes(value),
          then: yup.array().of(yup.string()).required()
        }),
    });

    const schema = alertType === 'U' ? userSchema : systemSchema;

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let formDataToSend = {
        "Nombre": formData.Nombre,
        "Destinatario": formData.Destinatario,
        "Tipo de Alerta o Aviso": data["Tipo de Alerta o Aviso"],
      };

      if (['CE', 'ME', 'EE'].includes(formData.Destinatario)) {
        formDataToSend['Emails específicos'] = formData['Emails específicos'];
      }

      if (['GR'].includes(formDataToSend.Destinatario)) {
        formDataToSend['Grupos de destinatarios'] = formData['Grupos de destinatarios'];
      }

      duplicateCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
