import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  accessRows: null,
  users: null,
  groups: null,
  accessMap: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //GET
    case actionTypes.GET_ACCESS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ACCESS_SETTINGS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        accessRows: action.payload.accessRows,
        groups: action.payload.groups,
        users: action.payload.users,
        accessMap: action.payload.accessMap,
      };
    case actionTypes.GET_ACCESS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //UPDATE
    case actionTypes.UPDATE_ACCESS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_ACCESS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_ACCESS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //CREATE
    case actionTypes.CREATE_ACCESS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_ACCESS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_ACCESS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    //DELETE
    case actionTypes.DELETE_ACCESS_SETTINGS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_ACCESS_SETTINGS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_ACCESS_SETTINGS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
