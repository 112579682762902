import { useDispatch } from 'react-redux';
import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from '@icarius-utils/date';
import { useMemo } from 'react';
import { calendarActionsTypeColors } from "@icarius-utils/colors";
import { modifyActionsAction } from '@icarius-pages/actions/actions';

const useActionsSchedule = (data, colaborators, groups) => {

  const dispatch = useDispatch();

  const handleSubmit = (id, newCreationDate, newReminderDate) => {
    const originalData = data.find((item) => item.internalCode === id);

    const dataToSend = {
      ...originalData,
      creationDate: formatDateYYYYMMDD(newCreationDate),
      reminderDate: formatDateYYYYMMDD(newReminderDate),
      FILES: null,
      deleteFile: false,
    }

    delete dataToSend.typeString;
    delete dataToSend.referenceEmployeeName;
    delete dataToSend.referenceGroupName;
    delete dataToSend.statusString;
    delete dataToSend.completeFilePath;

    dispatch(modifyActionsAction(dataToSend));
  }

  const staff = useMemo(() => {
    const getRowsWithActions = (array, key) => {
      return array.filter((arrayItem) => data.some((dataItem) => (dataItem.referenceEmployeeCode || dataItem.referenceGroupCode) === arrayItem[key]));
    }

    const filteredColaborators = getRowsWithActions(colaborators, 'key');
    const filteredGroups = getRowsWithActions(groups, 'id');

    return [
      ...filteredColaborators.map((item) => ({
        img: item.FOTOGRAFIA,
        id: item.key,
        value: item.name,
      })),
      ...filteredGroups.map((item) => ({
        img: item.img,
        id: item.id,
        value: item.name,
      })),
    ];
  }, [data, colaborators, groups])

  const shifts = useMemo(() => {
    return data.map((item) => ({
      id: item.internalCode,
      color: item.status === 'P' ? calendarActionsTypeColors.pending : calendarActionsTypeColors.done,
      start: createDateFromDDMMYYYY(item.creationDate),
      reminderDate: createDateFromDDMMYYYY(item.reminderDate),
      resource: item.referenceEmployeeCode || item.referenceGroupCode,
      title: item.typeString,
      tooltip: `${item.typeString} - ${item.referenceEmployeeName || item.referenceGroupName}`,
    }));
  }, [data])

  return {
    staff,
    shifts,
    handleSubmit,
  }
}

export default useActionsSchedule;