import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  entryList: [],
  conceptList: [],
  imputationList: [],
  distributionList: [],
  conceptsWithoutImputation: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        entryList: action.payload.entryList,
        conceptList: action.payload.conceptList,
        imputationList: action.payload.imputationList,
        distributionList: action.payload.distributionList,
        conceptsWithoutImputation: action.payload.conceptsWithoutImputation,
      };

    case actionTypes.DELETE_FULFILLED:
    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        conceptsWithoutImputation: action.payload.conceptsWithoutImputation,
      };

    case actionTypes.GET:
    case actionTypes.CREATE:
    case actionTypes.MODIFY:
    case actionTypes.DELETE:
    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };

    case actionTypes.GET_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.CREATE_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
