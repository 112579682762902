import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";

const CustomAlertDialog = withStyles({
  paper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    maxWidth: "80%",
    minWidth: "80%",
  },
})(Dialog);

const GridDialog = (props) => {

  const {
    open,
    data,
    locale,
    dateFormat,
    handleClose,
  } = props;

  const generateColumns = (data) => {
    if (!data) return [];

    let columnDefinitions = [];

    data.forEach((object) => {
      Object.keys(object).forEach((key) => {
        let mappedColumn = {
          headerName: key.toUpperCase(),
          field: key,
          filter: "agTextColumnFilter",
          cellClass: "stringType",
        };

        columnDefinitions.push(mappedColumn);
      });
    });

    //Remove duplicate columns
    return columnDefinitions.filter((column, index, self) => index === self.findIndex(colAtIndex => colAtIndex.field === column.field));
  }


  return (
    <CustomAlertDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("visualizationGrid")}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <CommonPage
          gridTitle={getLocalizedString("alerts")}
          ownColumnDef={generateColumns(data)}
          rowData={data}
          locale={locale}
          dateFormat={dateFormat}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
    </CustomAlertDialog>
  );
}

export default GridDialog;
