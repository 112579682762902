import { useState } from "react";
import * as yup from 'yup';

const useHandleFormDuplicate = (data, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'entry' || fieldName === 'concept') {
        return data[fieldName];
      }
      return "";
    }

    const fieldNames = [
      'entry',
      'concept',
      'condition',
      'account',
      'imputation',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^[A-Za-z0-9.;\-_@=]*/;

    const schema = yup.object().shape({
      'entry': yup.string().required(),
      'concept': yup.string().required(),
      'condition': yup.string().matches(regex),
      'account': yup.string().matches(regex).required(),
      'imputation': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {

      duplicateCallback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleFormDuplicate;
