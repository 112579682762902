import { useState } from "react";
import * as yup from 'yup';
import {
  createDateFromDDMMYYYY,
  formatDateYYYYMMDD,
  formatDateDDMM,
  createDateFromDDMM
} from "@icarius-utils/date";

const useHandleForm = (data, type, modifyCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {

      if (fieldName === 'Fecha de ejecucion específica') {
        return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : new Date();
      }

      if (fieldName === 'Fecha de ejecucion desde') {
        return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : new Date();
      }

      if (fieldName === 'Fecha de ejecucion hasta') {
        return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : new Date();
      }

      if (fieldName === 'Día y Mes del año de ejecución') {
        return data[fieldName] ? createDateFromDDMM(data[fieldName]) : new Date();
      }

      if (fieldName === "Nivel") {
        return data.nivelString;
      }

      return data[fieldName];
    }

    const userFieldNames = [
      'Tipo de Alerta o Aviso',
      'Nivel',
      'Nombre',
      'Activa',
      'Momento de ejecución',
      'Hora de ejecución',
      'Notificación',
      'Fecha de ejecucion específica',
      'Fecha de ejecucion desde',
      'Fecha de ejecucion hasta',
      'Día de la semana de ejecución',
      'Día del mes de ejecución',
      'Mes del año de ejecución',
      'Día y Mes del año de ejecución',
      'Destinatario',
      'Emails específicos',
      'Grupos de destinatarios',
      'Rol de Visualización',
      'Código de Alerta o Aviso',
      'Plantilla WhatsApp',
      'Proceso especial a ejecutar',
    ];

    const systemFieldNames = [
      'Tipo de Alerta o Aviso',
      'Nivel',
      'Nombre',
      'Activa',
      'Notificación',
      'Código de Alerta o Aviso',
      'Plantilla WhatsApp',
    ];

    const fieldNames = type === "S" ? systemFieldNames : userFieldNames;

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const userSchema = yup.object().shape({
      'Activa': yup.string().required(),
      'Momento de ejecución': yup.string().required(),
      'Hora de ejecución': yup.string().required(),
      'Notificación': yup.string().required(),
      'Fecha de ejecucion específica': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'FE',
          then: yup.string().required()
        }),
      'Fecha de ejecucion desde': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'RF',
          then: yup.string().required()
        }),
      'Fecha de ejecucion hasta': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'RF',
          then: yup.string().required()
        }),
      'Día de la semana de ejecución': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'SD',
          then: yup.string().required()
        }),
      'Día del mes de ejecución': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'MD',
          then: yup.string().required()
        }),
      'Mes del año de ejecución': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'AM',
          then: yup.string().required()
        }),
      'Día y Mes del año de ejecución': yup.string()
        .when('Momento de ejecución', {
          is: (value) => value === 'AF',
          then: yup.string().required()
        }),
      'Destinatario': yup.string().required(),
      'Emails específicos': yup.string().max(254)
        .when('Destinatario', {
          is: (value) => ['CE', 'ME', 'EE'].includes(value),
          then: yup.string().max(254).required(),
        }),
      'Grupos de destinatarios': yup.array().of(yup.string())
        .when('Destinatario', {
          is: (value) => ['GR'].includes(value),
          then: yup.array().of(yup.string()).min(1).required(),
        }),
      'Rol de Visualización': yup.string().required(),
      'Proceso especial a ejecutar': yup.string(),
    });

    const systemSchema = yup.object().shape({
      'Activa': yup.string().required(),
      'Notificación': yup.string().required(),
    });

    const schema = type === "S" ? systemSchema : userSchema;

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let formDataToSend;

      if (type === "U") {
        formDataToSend = { ...formData };
        formDataToSend['Fecha de ejecucion específica'] = formatDateYYYYMMDD(formDataToSend['Fecha de ejecucion específica']);
        formDataToSend['Fecha de ejecucion desde'] = formatDateYYYYMMDD(formDataToSend['Fecha de ejecucion desde']);
        formDataToSend['Fecha de ejecucion hasta'] = formatDateYYYYMMDD(formDataToSend['Fecha de ejecucion hasta']);

        let formattedDDMM = formatDateDDMM(formDataToSend['Día y Mes del año de ejecución']);
        if (formattedDDMM === 'Invalid date') {
          formattedDDMM = "";
        }

        formDataToSend['Día y Mes del año de ejecución'] = formattedDDMM;
      }

      if (type === "S") {
        formDataToSend = {
          'Tipo de Alerta o Aviso': formData['Tipo de Alerta o Aviso'],
          'Activa': formData['Activa'],
          'Notificación': formData['Notificación'],
          'Plantilla WhatsApp': formData['Plantilla WhatsApp'],
        }
      }

      modifyCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "Destinatario" && ["TD", "TM", "TE", "TC", "TP", "AD"].includes(value)) {
      setFormData({
        ...formData,
        [fieldName]: value,
        "Emails específicos": "",
        "Grupos de destinatarios": [],
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value
    });
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
