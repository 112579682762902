import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  colaborators: [],
  groups: [],
  typeList: [],
  statusList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET:
    case actionTypes.CREATE:
    case actionTypes.MODIFY:
    case actionTypes.DELETE:
      return { ...state, isLoading: true };

    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        colaborators: action.payload.colaborators,
        groups: action.payload.groups,
        typeList: action.payload.typeList,
        statusList: action.payload.statusList,
      };

    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };

    case actionTypes.GET_REJECTED:
    case actionTypes.CREATE_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
