import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'entry') {
        return isCreate ? 'Centralización' : data[fieldName];
      }

      if (fieldName === 'imputation') {
        return isCreate ? 'D' : data[fieldName];
      }

      if (fieldName === 'distribution') {
        return isCreate ? 'N' : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'entry',
      'concept',
      'condition',
      'account',
      'imputation',
      'reference',
      'detail',
      'headerValues',
      'detailValues',
      'distribution',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^[A-Za-z0-9.;\-_@=]*/;

    const schema = yup.object().shape({
      'entry': yup.string().max(20).required(),
      'concept': yup.string().max(8).required(),
      'condition': yup.string().max(254).matches(regex),
      'account': yup.string().matches(regex).max(210).required(),
      'imputation': yup.string().max(1).required(),
      'reference': yup.string().max(100).matches(regex),
      'detail': yup.string().max(50).matches(regex),
      'headerValues': yup.string().max(254).matches(regex),
      'detailValues': yup.string().max(254).matches(regex),
      'distribution': yup.string().max(1).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ internalCode: data.internalCode, ...dataToSend });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
