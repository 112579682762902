import * as actionTypes from "./actionTypes";
import {
  getAccumulatorsSettingsAPI,
  deleteAccumulatorsSettingsAPI,
  createAccumulatorsSettingsAPI,
  updateAccumulatorsSettingsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de un acumulador que comparta el mismo código o nombre";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getAccumulatorsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAccumulatorsSettingsAPI();

    let data = response.data && response.data.result;
    let months = response.data && response.data.monthsList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, months },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteAccumulatorAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAccumulatorsSettingsAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Acumulador se ha eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === "ACCUM_IN_USE"){
      dispatch(openSnackbarAction({ msg: "No es posible eliminar el acumulador porque está siendo utilizado en fórmulas", severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
  }
};

export const createAccumulatorAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createAccumulatorsSettingsAPI(permission);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Acumulador se ha creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: "No puede existir más de un acumulador que comparta el mismo código o nombre", severity: "error" }));
    }else{
      errorHandler(e, dispatch);
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyAccumulatorAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateAccumulatorsSettingsAPI(permission);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Acumulador se ha actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: "No puede existir más de un acumulador que comparta el mismo código o nombre", severity: "error" }));
    }else{
      errorHandler(e, dispatch);
    }
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};