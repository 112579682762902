import React, { useState } from "react";
import { TextField, Grid, Typography, Checkbox, Button } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createAccessSettingsAction, updateAccessSettingsAction, getAccessSettingsAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import { getInitialDataAction } from "src/app/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, users, groups, handleClose, rowsToUse, getGroupsText, getShouldDisabledIfThereAreAdmins } = props;

  const [groupDialogIsOpen, setGroupDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createAccessSettingsAction([dataToSend]))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getInitialDataAction())
            .then(() => {
              dispatch(getAccessSettingsAction())
                .then(() => handleClose());
            })
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateAccessSettingsAction([dataToSend]))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getInitialDataAction())
            .then(() => {
              dispatch(getAccessSettingsAction())
                .then(() => handleClose());
            })
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError, rowsToUse, groups, getShouldDisabledIfThereAreAdmins);

  const auxUser = {
    name: formData.name,
    code: formData.code,
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
            <Autocomplete
              disabled={!isCreate}
              options={users}
              value={auxUser}
              onChange={(event, newUser) => setFormValue(newUser, "name")}
              getOptionSelected={(selected) => Boolean(users.find(user => user.code === selected.code) !== undefined)}
              clearOnBlur
              closeIcon={null}
              noOptionsText={''}
              getOptionLabel={(user) => user.name}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label={getLocalizedString("selectManager")} />}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* email */}
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={getLocalizedString("email")}
              value={formData.email}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, minHeight: 75, height: '' }}>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => setFormValue(!Boolean(formData.active), "active")}>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {getLocalizedString("managerActive")}
              </Typography>
              <Checkbox checked={formData.active} />
            </div>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => setFormValue(!Boolean(formData.employer), "employer")}>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {getLocalizedString("isEmployer")}
              </Typography>
              <Checkbox checked={formData.employer} />
            </div>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => Boolean(formData.employer) && setFormValue(!Boolean(formData.isAdmin), "isAdmin")}>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'Administrador de ICARIUS'}
              </Typography>
              <Checkbox checked={formData.isAdmin} />
            </div>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => setFormValue(!Boolean(formData.requestRecipient), "requestRecipient")}>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'¿Puede recibir solicitudes?'}
              </Typography>
              <Checkbox checked={formData.requestRecipient} />
            </div>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }} onClick={() => Boolean(formData.employer) && setFormValue(!Boolean(formData.receiveActions), "receiveActions")}>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'¿Puede recibir acciones?'}
              </Typography>
              <Checkbox checked={formData.receiveActions} />
            </div>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: 24 }}>
            <div style={{ display: "flex", alignItems: 'center', paddingRight: 20 }}>
              <Button
                variant="outlined"
                onClick={() => !getShouldDisabledIfThereAreAdmins() && setGroupDialogIsOpen(true)}
                style={{ minWidth: 200, marginRight: 20 }}
              >
                {'Seleccionar grupos'}
              </Button>
              <Typography className="whiteText">
                {`${getGroupsText(formData)} seleccionados`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify={"flex-end"}
          alignItems="center"
          style={{
            visibility: !getShouldDisabledIfThereAreAdmins() ? 'visible' : 'hidden',
            paddingBottom: 20
          }}
        >
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        groupDialogIsOpen &&
        <UserSelectionDialog
          open={groupDialogIsOpen}
          initialData={formData.groups} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          users={groups}
          title={getLocalizedString("viewAccessGroups")}
          selectedUsersTitle={getLocalizedString("currentGroups")}
          helperText={getLocalizedString("groupsWarning")}
          filterPlaceholder={getLocalizedString("findGroupByName")}
          handleSubmit={(data) => setFormValue(data, "groups")}
          handleClose={() => setGroupDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default Form;
