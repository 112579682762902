import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getColaborators,
  getTypeList,
  getStatusList,
  getGroups,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteActionsAction,
  getActionsAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getUserData } from "src/app/selectors";

const useActions = () => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const colaborators = useSelector(getColaborators);
  const typeList = useSelector(getTypeList);
  const statusList = useSelector(getStatusList);
  const groups = useSelector(getGroups);
  const { code } = useSelector(getUserData);

  useEffect(() => {
    dispatch(getActionsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: documents.length === 1 ? "¿Desea eliminar el registro?" : "¿Desea eliminar los registros?",
      onConfirm: () => handleDelete({ codes: documents.map(el => el.internalCode) }),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleDelete = (documents) => {
    dispatch(deleteActionsAction(documents));
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleOpenCalendar = () => {
    setCalendarIsOpen(true);
  }

  const handleCloseCalendar = () => {
    setCalendarIsOpen(false);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    calendarIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDuplicateDialog,
    handleOpenCalendar,
    handleCloseCalendar,
  }

  return {
    data,
    employeeCode: code,
    colaborators,
    groups,
    typeList,
    statusList,
    isLoading,
    state,
    handlers
  }
}

export default useActions;