import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import CommonPage from "@icarius-common/commonPage";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AlertList from "./alertList";
import GridDialog from "./gridDialog";
import useAlerts from "./useAlerts";

const Alerts = () => {

  const {
    isLoading,
    data,
    types,
    dateFormat,
    locale,
    state,
    handlers,
  } = useAlerts();

  const [anchorEl, setAnchorEl] = useState(null);

  const color = useSelector(getAppColor);
  const gridTheme = useSelector(getTheme);

  const deleteItem = () => (
    <CustomIconButton
      title={state.selectedCodes.length ? 'Eliminar alertas seleccionadas' : 'Eliminar todas las leídas'}
      onClick={() => handlers.handleOpenDeleteDialog()}
      type={"delete"}
    />
  )

  const filterItem = () => (
    <div style={{ width: 300, marginLeft: 10, marginRight: 10 }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel shrink id={`label-typeSelected`}>{"Seleccionar filtro"}</InputLabel>
        <Select
          value={state.typeSelected}
          labelId={`label-typeSelected`}
          id={`select-typeSelected`}
          onChange={(e) => handlers.handleSelectType(e.target.value)}
          margin={"none"}
          displayEmpty
        >
          <MenuItem className={"whiteText"} value={''}>{'Sin filtro'}</MenuItem>
          {
            types?.map(item => (
              <MenuItem
                className={"whiteText"}
                key={item}
                value={item}>
                {item}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  )

  const viewUnreaditem = () => (
    <div className="buttons" style={{ marginLeft: "auto" }}>
      <FormControlLabel
        control={
          <Switch
            checked={state.viewUnread}
            value={state.viewUnread}
            onChange={(e) => handlers.handleChangeViewUnread(e.target.checked)}
          />
        }
        label={getLocalizedString(state.viewUnread ? "showUnreadAlerts" : "showAllAlerts")}
        labelPlacement="start"
        style={{ marginRight: 5 }}
      />
    </div>
  )

  const helpItem = () => (
    <div>
      <CustomIconButton
        title={getLocalizedString("help")}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        type={"help"}
      />
      <StyledHelpMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemWithIcon value={"https://www.youtube.com/embed/Am30JRLMlW4"} text={getLocalizedString("alerts")} type={"video"} />
      </StyledHelpMenu>
    </div>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      isNotGridPage
      title={getLocalizedString("alerts")}
      menuItems={[viewUnreaditem, filterItem, deleteItem, helpItem]}
      audioName={"Alertas y Avisos"}
    >
      <div className="backgroundSecondary" style={{ padding: "0 20px", backgroundColor: "transparent" }}>
        <AlertList
          isLoading={isLoading}
          alerts={data}
          viewUnread={state.viewUnread}
          filteredAlertType={state.typeSelected}
          selectedCodes={state.selectedCodes}
          color={color}
          gridTheme={gridTheme}
          handleOpenGridDialog={handlers.handleOpenGridDialog}
          handleModifyStatus={handlers.handleModifyStatus}
          handleDelete={handlers.handleOpenDeleteDialog}
          handleChangeSelectedCodes={handlers.handleChangeSelectedCodes}
        />
      </div>
      {
        state.gridDialogIsOpen &&
        <GridDialog
          open={state.gridDialogIsOpen}
          data={state.gridData}
          locale={locale}
          dateFormat={dateFormat || "dd/mm/yyyy"}
          handleClose={handlers.handleCloseGridDialog}
        />
      }
    </CommonPage>
  )
}

export default Alerts;