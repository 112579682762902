import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createAccountingImputationsAction, modifyAccountingImputationsAction } from "../../../actions";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, entryList, conceptList, imputationList, distributionList, loading, data, handleClose } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createAccountingImputationsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyAccountingImputationsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError);

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear índice" : "Editar índice"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* entry */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-entry`}>{"Asiento"}</InputLabel>
                <Select
                  value={formData.entry}
                  labelId={`label-entry`}
                  id={`select-entry`}
                  onChange={(e) => setFormValue(e.target.value, "entry")}
                  margin={"none"}
                >
                  {
                    entryList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* concept */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-concept`}>{"Concepto"}</InputLabel>
                <Select
                  value={formData.concept}
                  labelId={`label-concept`}
                  id={`select-concept`}
                  onChange={(e) => setFormValue(e.target.value, "concept")}
                  margin={"none"}
                >
                  {
                    conceptList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* condition */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Condición'}
                value={formData.condition}
                onChange={(e) => setFormValue(e.target.value, "condition")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* account */}
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Cuenta contable'}
                value={formData.account}
                onChange={(e) => setFormValue(e.target.value, "account")}
                inputProps={{ maxLength: 210 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* imputation */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-imputation`}>{"Imputación"}</InputLabel>
                <Select
                  value={formData.imputation}
                  labelId={`label-imputation`}
                  id={`select-imputation`}
                  onChange={(e) => setFormValue(e.target.value, "imputation")}
                  margin={"none"}
                >
                  {
                    imputationList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* reference */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Referencia'}
                value={formData.reference}
                onChange={(e) => setFormValue(e.target.value, "reference")}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* detail */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Información detallada'}
                value={formData.detail}
                onChange={(e) => setFormValue(e.target.value, "detail")}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* headerValues */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valores para cabecera'}
                value={formData.headerValues}
                onChange={(e) => setFormValue(e.target.value, "headerValues")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* detailValues */}
              <TextField
                fullWidth
                margin={"none"}
                label={'Valores para detalle'}
                value={formData.detailValues}
                onChange={(e) => setFormValue(e.target.value, "detailValues")}
                inputProps={{ maxLength: 254 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* distribution */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-distribution`}>{"Distribución"}</InputLabel>
                <Select
                  value={formData.distribution}
                  labelId={`label-distribution`}
                  id={`select-distribution`}
                  onChange={(e) => setFormValue(e.target.value, "distribution")}
                  margin={"none"}
                >
                  {
                    distributionList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
