import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if(fieldName === 'selectedEmployees'){
        return isCreate ? [] : data[fieldName];  
      }

      return isCreate ? '' : data[fieldName];
    }

    const fieldNames = ["code", "name", "type", "selectedEmployees"];
    
    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })      
    }, {});
  }

  const [ formData, setFormData ] = useState(createInitialData());

  const dataIsValid = () => {
    const { code, name, type } = formData;
    if(!code || code === "") return false;
    if(!name || name === "") return false;
    if(!type || type === "") return false;
    return true;
  }

  const submit = () => {
    if(dataIsValid()){
      let dataToSend = { ...formData };

      if(!isCreate){
        dataToSend = { ...dataToSend, internalCode: data.internalCode };
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    }else{
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    let newFormData = {
      ...formData,
      [fieldName]: value,
    }

    if(fieldName === "type"){ //si se cambia el type, setear como code tambien type
      newFormData.code = value;
    }

    setFormData(newFormData)
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
