import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import { Grid } from "@material-ui/core";
import paths from "@icarius-localization/paths";
import { getIsAdmin } from "src/app/selectors";
import DataItem from "./data/dataItem";
import { getAlertsTemplatesAction } from "../actions";
import { RESET_STATE } from "../actionTypes";
import { getData, getLoading } from "../selectors";

const AlertsTemplates = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);

  useEffect(() => {
    dispatch(getAlertsTemplatesAction());
    return () => dispatch({ type: RESET_STATE })
  }, [dispatch])

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.alertsSettings);
  }

  return (
    <ABMLayout
      title={'Plantillas de WhatsApp'}
      isLoading={isLoading}
      showCreateButton={isAdmin && !createIsOpen}
      createButtonTitle={'Nueva plantilla'}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={'No se encontraron plantillas'}
      noDataCreateTitle={'Nueva plantilla'}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      <Grid container item xs={12} direction="row">
        {
          createIsOpen &&
          <Grid container item xs={12} style={{ margin: "10px 0px" }}>
            <DataItem
              data={null}
              closeNew={() => setCreateIsOpen(false)}
              isAdmin={isAdmin}
            />
          </Grid>
        }
        {
          data && data.map((item) => {
            return (
              <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                <DataItem data={item} isAdmin={isAdmin} />
              </Grid>
            )
          })
        }
      </Grid>
    </ABMLayout>
  );
}

export default AlertsTemplates;
