import React from 'react';
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getAccessSettings,
  createAccessSettings,
  updateAccessSettings,
  deleteAccessSettings,
} from "@icarius-connection/api";
import { openDialogAction } from "@icarius-common/dialog/actions";

const displayUserProcessError = (dispatch) => {
  dispatch(openDialogAction({
    title: 'Atención',
    msg: <>
      El usuario está asignado para la ejecución de los cálculos en alertas.
      <br />
      <br />
      Antes de cambiar su estado de Staff de RR.HH. o de eliminarlo, reemplace al usuario por otro, en ‘Accesos y Propiedades’
    </>,
    acceptOnly: true,
  }));
}

export const getAccessSettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACCESS_SETTINGS });
  try {
    let response = await getAccessSettings();

    dispatch({
      type: actionTypes.GET_ACCESS_SETTINGS_FULFILLED,
      payload: {
        accessRows: response.data.result.rows,
        groups: response.data.result.groups,
        users: response.data.result.users,
        accessMap: response.data.result.accessMap,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ACCESS_SETTINGS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

//UPDATES
export const updateAccessSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_ACCESS_SETTINGS });
  try {
    let response = await updateAccessSettings({ accessSettings: data });

    dispatch({ type: actionTypes.UPDATE_ACCESS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("managersUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("errorTwoEmployersAlready"), severity: "error" }));
    } else {
      if (e.response.data.status === "USER_PROCESS") {
        displayUserProcessError(dispatch);
      } else {
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    dispatch({ type: actionTypes.UPDATE_ACCESS_SETTINGS_REJECTED, payload: e });
    return e.response;
  }
};

//CREATE
export const createAccessSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_ACCESS_SETTINGS });
  try {
    let response = await createAccessSettings({ accessSettings: data });

    dispatch({ type: actionTypes.CREATE_ACCESS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("managersCreatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("errorTwoEmployersAlready"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_ACCESS_SETTINGS_REJECTED, payload: e });
    return e.response;
  }
};

//DELETE
export const deleteAccessSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_ACCESS_SETTINGS });
  try {
    let response = await deleteAccessSettings(data);

    dispatch({ type: actionTypes.DELETE_ACCESS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("managerDeletedOK"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_ACCESS_SETTINGS_REJECTED, payload: e });
    if (e.response.data.status === "USER_PROCESS") {
      displayUserProcessError(dispatch);
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    return e.response;
  }
};
