import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import TabMenu from "@icarius-common/tabMenu";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const AccessMapGridDialog = (props) => {

  const {
    open,
    data,
    handleClose,
  } = props;

  const [optionToShow, setOptionToShow] = useState(0);

  const getData = () => [data.rol, data.employers, data.managers][optionToShow];

  const generateColumns = () => {
    let columnDefinitions = getColumnDefByPage(optionToShow === 0 ? "/accessSettings" : "accessSettings/managersEmployers");
    const dataToUse = getData();
    if (dataToUse.length > 0) {
      Object.keys(dataToUse[0]).forEach(key => {
        const arrayToIgnore = [
          'Apellidos y nombres (Managers/Empleadores)',
          'Administrador de Icarius',
          'Puede recibir solicitudes',
          'Apellidos y nombres',
          'Email',
          'Mail',
          'Status',
          'Estado',
          'Grupo',
          'Personal de planta',
          'Rol',
          'Grupos con acceso',
        ]

        if (!arrayToIgnore.includes(key)) {
          let item = {
            headerName: key,
            field: key,
            filter: "agSetColumnFilter",
            cellClass: "stringType",
          };

          columnDefinitions.push(item);
        }
      });
    }
    return columnDefinitions;
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {'Mapa de acceso'}
      </DialogTitle>
      <DialogContent>
        <TabMenu
          options={[
            'Por ROL',
            'Empleadores',
            'Managers',
          ]}
          optionSelected={optionToShow}
          onClick={(value) => setOptionToShow(value)}
        />
        <CommonPage
          gridTitle={'Mapa de acceso'}
          ownColumnDef={generateColumns()}
          rowData={getData()}
          gridHeight={'60vh'}
          hasSelectAll
          hasExpand
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default AccessMapGridDialog;