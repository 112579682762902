import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import { AlertsSystemIcon, AlertsUserIcon, AlertsTemplateIcon } from "@icarius-icons/";
import paths from "@icarius-localization/paths";

const AlertsSettingsBox = () => {

  const data = [
    {
      name: "Alertas y avisos del sistema",
      icon: <AlertsSystemIcon />,
      path: paths.systemAlertsSettings,
    },
    {
      name: "Alertas y avisos del usuario",
      icon: <AlertsUserIcon />,
      path: paths.userAlertsSettings,
    },
    {
      name: "Plantillas de WhatsApp",
      icon: <AlertsTemplateIcon />,
      path: paths.alertsTemplates,
    },
  ];

  return (
    <ScreenSelector
      title={'Definición de alertas y avisos'}
      data={data}
    />
  );
}

export default AlertsSettingsBox;
