import React from "react";
import Form from "./form";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { useSelector } from "react-redux";
import { getActivationRow } from "../../selectors";

const FormContainer = (props) => {

  const { isLoading, alertType, handleClose } = props;

  const activationRow = useSelector(getActivationRow);

  const goBackItem = () =>
  <CustomIconButton
    title={getLocalizedString("goBack")}
    onClick={() => handleClose(false)}
    type={"goBack"}
  />

  return (
    <CommonPage
      title={`Activación de alerta/aviso - ${activationRow.Nombre}`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage      
    >
      <Form
        data={activationRow}
        alertType={alertType}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default FormContainer;
