import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const ViewGridDialog = (props) => {

  const {
    open,
    data,
    handleClose,
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`Conceptos sin imputación`}
      </DialogTitle>
      <DialogContent>
        <CommonPage
          gridTitle={'Conceptos sin imputación'}
          columnDefPage={'accountingImputationsViewExtra'}
          rowData={data}
          gridHeight={'55vh'}
          hasSelectAll
          hasExpand
          hasHelp
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default ViewGridDialog;