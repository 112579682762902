import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getDestinatarioList,
  getGroupsList,
} from "../../selectors";

const gridStyle = { height: 75, padding: "0px" };

const DuplicateDialog = (props) => {

  const { open, data, alertType, handleConfirm, handleClose } = props;

  const destinatarioList = useSelector(getDestinatarioList);
  const groupsList = useSelector(getGroupsList);

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, alertType, handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div style={{ width: '90%' }}>
          {`Duplicar alerta/aviso: ${data.Nombre}`}
        </div>
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre"}
              onChange={(e) => setFormValue(e.target.value, "Nombre")}
              value={formData.Nombre}
              inputProps={{ maxLength: 256 }}
            />
          </Grid>
          {
            alertType === 'U' &&
            <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Destinatario */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Destinatario`}>{"Destinatario"}</InputLabel>
                <Select
                  value={formData.Destinatario}
                  labelId={`label-Destinatario`}
                  id={`select-Destinatario`}
                  onChange={(e) => setFormValue(e.target.value, "Destinatario")}
                  margin={"none"}
                >
                  {
                    destinatarioList && destinatarioList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {
            formData.Destinatario === 'GR' &&
            <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Grupos de destinatarios  */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Grupos de destinatarios`}>{"Grupos de destinatarios"}</InputLabel>
                <Select
                  multiple
                  value={formData['Grupos de destinatarios']}
                  labelId={`label-Grupos de destinatarios`}
                  id={`select-Grupos de destinatarios`}
                  onChange={(e) => setFormValue(e.target.value, "Grupos de destinatarios")}
                  margin={"none"}
                >
                  {
                    groupsList && groupsList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          {
            ['CE', 'ME', 'EE'].includes(formData.Destinatario) &&
            <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Emails específicos */}
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Emails específicos"}
                onChange={(e) => setFormValue(e.target.value, "Emails específicos")}
                value={formData['Emails específicos']}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
