import * as actionTypes from "./actionTypes";

const initialState = {
  activationRow: {},
  rows: [],
  isLoading: false,

  nivelList: [],
  claseList: [],
  selcolList: [],
  destinatarioList: [],
  groupsList: [],

  activaList: [],
  exemomList: [],
  exediassList: [],
  exediasmList: [],
  exemmList: [],
  notificationsList: [],
  roleList: [],
  templateList: [],
  processList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        nivelList: action.payload.nivelList,
        claseList: action.payload.claseList,
        selcolList: action.payload.selcolList,
        destinatarioList: action.payload.destinatarioList,
        groupsList: action.payload.groupsList,
      };

    case actionTypes.GET_ACTIVATION_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        activationRow: action.payload.activationRow,
        activaList: action.payload.activaList,
        destinatarioList: action.payload.destinatarioList,
        exediasmList: action.payload.exediasmList,
        exediassList: action.payload.exediassList,
        exemmList: action.payload.exemmList,
        exemomList: action.payload.exemomList,
        notificationsList: action.payload.notificationsList,
        groupsList: action.payload.groupsList,
        roleList: action.payload.roleList,
        templateList: action.payload.templateList,
        processList: action.payload.processList,
      };

    case actionTypes.GET_ROWS:
    case actionTypes.GET_ACTIVATION_ROWS:
    case actionTypes.CREATE:
    case actionTypes.MODIFY:
    case actionTypes.DELETE:
    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };

    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
    case actionTypes.DELETE_FULFILLED:
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
      };

    case actionTypes.GET_ROWS_REJECTED:
    case actionTypes.GET_ACTIVATION_ROWS_REJECTED:
    case actionTypes.CREATE_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
