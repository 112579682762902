import paths from "@icarius-localization/paths";

export default [
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "Código de concepto",
            field: "code",
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "Nombre del concepto",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "ID",
            field: "id",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "Familia",
            field: "family",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "Clasificación",
            field: "classification",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['accountingImputationsViewExtra'],
        config: {
            headerName: "Disponibilidad",
            field: "availability",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Asiento',
            field: 'entryString',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Concepto',
            field: 'conceptString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Condición',
            field: 'condition',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Cuenta contable',
            field: 'account',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Imputación',
            field: 'imputationString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Referencia',
            field: 'reference',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Información detallada',
            field: 'detail',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Valores para cabecera',
            field: 'headerValues',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Valores para detalle',
            field: 'detailValues',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.accountingImputations],
        config: {
            headerName: 'Distribución',
            field: 'distributionString',
            filter: "agTextColumnFilter",
        }
    },
]