import React from "react";
import {
    Typography,
    Button,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Checkbox,
    IconButton,
} from "@material-ui/core";
import {
    DeleteIcon,
    PeopleIcon,
    RoomIcon,
    DownloadIcon,
    LinkIcon,
    ErrorIcon,
} from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getLocalizedString } from "@icarius-localization/strings";
import { hexToRgb, generalColors } from "@icarius-utils/colors";
import { HOVER_OPACITY } from "@icarius-utils/constants";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";

const CustomExpansionPanelDetails = withStyles({
    root: {
        paddingTop: 0,
        marginRight: 33,
    },
})(ExpansionPanelDetails);

const AlertItem = (props) => {

    const {
        data,
        color,
        gridTheme,
        handleDelete,
        handleModifyStatus,
        handleOpenGridDialog,
        selectedCodes,
        handleChangeSelectedCodes,
    } = props;

    const handlePositionClick = (coordinates) => {
        if (coordinates) {
            const url = "https://www.google.com/maps/search/?api=1&query=" + coordinates.latitude + "," + coordinates.longitude;
            window.open(url, "_blank");
        }
    };

    const openLink = (url) => {
        let newUrl = url;
        if (!url.match(/^https?:\/\//i)) {
            newUrl = 'http://' + url;
        }
        window.open(newUrl, "_blank");
    };

    const CustomButton = withStyles({
        root: {
            textTransform: "none",
            backgroundColor: gridTheme.theme === "dark" ? (data.isRead ? "#484848" : "#747474") : (data.isRead ? "white" : "#e3e3e3"),
            "&:hover": {
                backgroundColor: hexToRgb(color, HOVER_OPACITY),
            },
        },
    })(Button);

    const CustomExpansionPanel = withStyles({
        rounded: {
            borderRadius: "10px",
            "&:first-child": {
                borderRadius: "10px",
                marginTop: "0 !important",
            },
            "&:last-child": {
                borderRadius: "10px",
            }
        },
        root: {
            backgroundColor: gridTheme.theme === "dark" ?
                (data.isRead ? "#747474" : "#484848")
                : (data.isRead ? "#e3e3e3" : "white"),
            margin: "8px 0px",
        },
        expanded: {
            margin: "8px 0px !important",
        },
    })(ExpansionPanel);

    return (
        <CustomExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container item>
                    <Grid container item xs={11}>
                        <Grid container item xs={12} sm={6} md={2} alignItems="center">
                            <Checkbox
                                onChange={() => handleChangeSelectedCodes(data.code)}
                                checked={selectedCodes.includes(data.code)}
                            />
                            <Typography variant="subtitle1" style={{ fontWeight: 600 }} className="whiteText">
                                {data.alertClass}
                            </Typography>
                        </Grid>
                        <Grid container item sm={9} md={7} alignItems="center">
                            <Typography variant="subtitle1" className="whiteText">
                                {data.title}
                            </Typography>
                        </Grid>
                        <Grid container item xs={11} md={2} alignItems="center">
                            <Typography variant="subtitle1" className="whiteText">
                                {data.date + " " + data.time + "hrs"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={1}>
                        <Grid container item xs={12} alignItems="center" justify="flex-end">
                            <ErrorIcon style={{ color: data.type === "A" ? generalColors.red : "transparent" }} />
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <CustomExpansionPanelDetails>
                <Grid container style={{ margin: '0px 10px' }}>
                    <Grid container direction="column" item xs={12}>
                        <Typography variant="subtitle1" className="whiteText">
                            {data.text}
                        </Typography>
                        {
                            data.references &&
                            <>
                                <Typography className={"subtitleAlert whiteText"} variant="subtitle1" style={{ fontWeight: 800 }}>
                                    {getLocalizedString("references")}
                                </Typography>
                                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                                    <Typography variant="body1" className="whiteText">
                                        {
                                            data.references.split(/\r\n|\r|\n|\/n/).map(function (item, key) {
                                                return (
                                                    <div key={key} dangerouslySetInnerHTML={{
                                                        __html: item
                                                    }}>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Typography>
                                </div>
                            </>
                        }
                    </Grid>
                    <Grid container item xs={12} justify="flex-end" style={{ padding: '20px 0px' }}>
                        {
                            data.attachment &&
                            <IconButton disableRipple onClick={() => previewFromURL(RESOURCES_ENDPOINT + data.attachment)}>
                                <DownloadIcon style={{ color: "var(--icons)" }} />
                            </IconButton>
                        }
                        {
                            data.link &&
                            <IconButton disableRipple onClick={() => openLink(data.link)}>
                                <LinkIcon style={{ color: "var(--icons)" }} />
                            </IconButton>
                        }
                        {
                            data.coordinates &&
                            <IconButton disableRipple onClick={() => handlePositionClick(data.coordinates)}>
                                <RoomIcon style={{ color: "var(--icons)" }} />
                            </IconButton>
                        }
                        {
                            Boolean(data?.visualizationGrid?.length) &&
                            <IconButton disableRipple onClick={() => handleOpenGridDialog(data)}>
                                <PeopleIcon style={{ color: "var(--icons)" }} />
                            </IconButton>
                        }
                        <Grid item style={{ paddingRight: "10px" }}>
                            <IconButton disableRipple onClick={() => handleDelete(data.code)}>
                                <DeleteIcon style={{ color: "var(--icons)" }} />
                            </IconButton>
                        </Grid>
                        <CustomButton
                            disableRipple={true}
                            className="whiteText"
                            variant={"contained"}
                            onClick={() => handleModifyStatus(data.code)}
                        >
                            {getLocalizedString(data.isRead ? "markAsUnread" : "markAsRead")}
                        </CustomButton>
                    </Grid>
                </Grid>
            </CustomExpansionPanelDetails>
        </CustomExpansionPanel>
    );
}

export default AlertItem;