import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.actions],
        config: {
            headerName: 'Fecha de registro de la acción',
            field: "creationDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Tipo de acción',
            field: "typeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Estado',
            field: "statusString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Adjunto',
            field: "completeFilePath",
            filter: "agTextColumnFilter",
            cellRenderer: "ExternalFileRenderer"
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Descripción de la acción',
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Fecha recordatoria para alerta',
            field: "reminderDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Empleado de referencia',
            field: "referenceEmployeeName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.actions],
        config: {
            headerName: 'Grupo de referencia',
            field: "referenceGroupName",
            filter: "agSetColumnFilter",
        }
    },
]