import NAME from "./constants";

export const GET_ACCESS_SETTINGS = NAME + "/GET_ACCESS_SETTINGS";
export const GET_ACCESS_SETTINGS_FULFILLED = NAME + "/GET_ACCESS_SETTINGS_FULFILLED";
export const GET_ACCESS_SETTINGS_REJECTED = NAME + "/GET_ACCESS_SETTINGS_REJECTED";

export const CREATE_ACCESS_SETTINGS = NAME + "/CREATE_ACCESS_SETTINGS";
export const CREATE_ACCESS_SETTINGS_FULFILLED = NAME + "/CREATE_ACCESS_SETTINGS_FULFILLED";
export const CREATE_ACCESS_SETTINGS_REJECTED = NAME + "/CREATE_ACCESS_SETTINGS_REJECTED";

export const UPDATE_ACCESS_SETTINGS = NAME + "/UPDATE_ACCESS_SETTINGS";
export const UPDATE_ACCESS_SETTINGS_FULFILLED = NAME + "/UPDATE_ACCESS_SETTINGS_FULFILLED";
export const UPDATE_ACCESS_SETTINGS_REJECTED = NAME + "/UPDATE_ACCESS_SETTINGS_REJECTED";

export const DELETE_ACCESS_SETTINGS = NAME + "/DELETE_ACCESS_SETTINGS";
export const DELETE_ACCESS_SETTINGS_FULFILLED = NAME + "/DELETE_ACCESS_SETTINGS_FULFILLED";
export const DELETE_ACCESS_SETTINGS_REJECTED = NAME + "/DELETE_ACCESS_SETTINGS_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";

