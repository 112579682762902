import React, { useMemo } from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  Eventcalendar,
  setOptions,
  Popup,
  Datepicker,
  localeEs,
} from '@mobiscroll/react';
import { Typography } from '@material-ui/core';

setOptions({
  locale: localeEs,
  theme: 'material',
  themeVariant: 'light'
});

const responsivePopup = {
  medium: {
    display: 'center',
    fullScreen: false,
    touchUi: false,
    showOverlay: false
  }
};

const Schedule = (props) => {

  const {
    staff,
    shifts,
    scheduleFunctions,
    scheduleData,
    view,
  } = props;

  const viewSettings = useMemo(() => {
    if(view === 'year') {
      return ({
          calendar: {
              type: 'year',
          }
      });
    }

    return ({
      timeline: {
        type: view,
        eventList: true,
      }
    })
  }, [view])

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Typography className="whiteText" noWrap style={{ margin: 10, marginTop: 0 }}>
        Click en una acción planificada para cambiar la fecha de registro de la acción o la fecha de la alerta
      </Typography>
      <Eventcalendar
        view={viewSettings}
        resources={staff}
        data={shifts}
        marked={shifts} // para la vista anual
        extendDefaultEvent={scheduleFunctions.extendDefaultEvent}
        onEventClick={scheduleFunctions.onEventClick}
        renderResource={scheduleFunctions.renderMyResource}
        renderEvent={scheduleFunctions.renderEvent}
        dragToCreate={false}
        dragToResize={false}
        dragToMove={false}
        clickToCreate={false}
        cssClass="md-employee-shifts"
        height={'calc(100vh - 225px)'}
        dayNamesShort={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
        defaultSelectedDate={new Date()}
      />
      {
        scheduleData.popUpIsOpen &&
        <Popup
          isOpen={scheduleData.popUpIsOpen}
          headerText={scheduleData.headerText}
          onClose={scheduleFunctions.onClose}
          buttons={scheduleFunctions.getPopupButtons()}
          responsive={responsivePopup}
          fullScreen={true}
          contentPadding={false}
          display="bottom"
          cssClass="employee-shifts-popup"
        >
          <div className="mbsc-form-group">
            <Datepicker
              label='Nueva fecha de registro de la acción'
              inputTyping={false}
              onChange={(e) => scheduleFunctions.handleDateChange(e.value, 'creation')}
              value={scheduleData.newCreationDate}
              valid={[{
                start: scheduleData.selectedShift.start,
                end: '2099-12-31',
              }]}
            />
            <Datepicker
              label='Nueva fecha recordatoria para alerta'
              inputTyping={false}
              onChange={(e) => scheduleFunctions.handleDateChange(e.value, 'reminder')}
              value={scheduleData.newReminderDate}
              valid={[{
                start: scheduleData.newCreationDate,
                end: '2099-12-31',
              }]}
            />
          </div>
        </Popup>
      }
    </div>
  )
}

export default Schedule;