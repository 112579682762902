import React, { useState } from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { createActionsAction, modifyActionsAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

const gridStyle = { height: 75, padding: "0px 10px" };
const gridStyleSelect = { height: 83, padding: "0px 10px" };
const gridStyleNoHeight = { padding: "0px 10px", marginTop: "15px" };
const buttonStyle = {
  color: "white",
  borderRadius: "6px",
  height: "52px"
}
const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
  },
})(Dialog);

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});


const CreateEditDialog = (props) => {

  const {
    open,
    colaborators,
    groups,
    employeeCode,
    statusList,
    typeList,
    loading,
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();
  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);

  const create = (dataToSend) => {
    if (dataToSend.mode === "CP" && (!dataToSend.points || !parseInt(dataToSend.points))) {
      dispatch(openSnackbarAction({
        msg: "Debe ingresar los puntos requeridos para el beneficio",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (Boolean(dataToSend.concept) && (!dataToSend.value || dataToSend.value === "0.00" || dataToSend.value === 0.00)) {
      dispatch(openSnackbarAction({
        msg: "Para definir un concepto de pago remunerativo asociado al beneficio, el valor del beneficio debe ser mayor a cero",
        severity: "error",
        duration: null,
      }));
      return;
    }

    dispatch(createActionsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    if (dataToSend.mode === "CP" && (!dataToSend.points || !parseInt(dataToSend.points))) {
      dispatch(openSnackbarAction({
        msg: "Debe ingresar los puntos requeridos para el beneficio",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (Boolean(dataToSend.concept) && (!dataToSend.value || dataToSend.value === "0.00" || dataToSend.value === 0.00)) {
      dispatch(openSnackbarAction({
        msg: "Para definir un concepto de pago remunerativo asociado al beneficio, el valor del beneficio debe ser mayor a cero",
        severity: "error",
        duration: null,
      }));
      return;
    }

    dispatch(modifyActionsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, employeeCode, create, modify, openValidationError);

  if (loading) return null;

  const setValueUserSelectionDialog = (value) => {
    if (!value) return;

    if (groups.some((item) => item.id === value)) {
      setFormValue(value, "referenceGroupCode");
      return;
    }

    if (colaborators.some((item) => item.key === value)) {
      setFormValue(value, "referenceEmployeeCode");
    }
  }

  return (
    <>
      <StyledDialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {isCreate ? "Crear acción" : "Editar acción"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ paddingTop: 0 }}>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    required
                    label={'Fecha de registro de la acción'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="dd/MM/yyyy"
                    margin="normal"
                    minDate={formData.creationDate || new Date()}
                    value={formData.creationDate}
                    onChange={(e) => setFormValue(e, "creationDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyleSelect}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-status`}>{"Tipo de acción"}</InputLabel>
                  <Select
                    required
                    value={formData.type}
                    labelId={`label-type`}
                    id={`select-type`}
                    onChange={(e) => setFormValue(e.target.value, "type")}
                    margin={"none"}
                  >
                    {
                      typeList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}>
                <TextField
                  required
                  fullWidth
                  margin={"none"}
                  label={'Descripción de la acción'}
                  value={formData.description}
                  onChange={(e) => setFormValue(e.target.value, "description")}
                  multiline={true}
                  variant="outlined"
                  rows={4}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    spellCheck: 'false',
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyleSelect}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-status`}>{"Estado"}</InputLabel>
                  <Select
                    disabled={isCreate}
                    value={formData.status}
                    labelId={`label-status`}
                    id={`select-status`}
                    onChange={(e) => setFormValue(e.target.value, "status")}
                    margin={"none"}
                  >
                    {
                      statusList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    required
                    label={'Fecha recordatoria para alerta'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData.reminderDate}
                    minDate={formData.creationDate}
                    onChange={(e) => setFormValue(e, "reminderDate")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container item alignItems="center" justify="center" xs={12} style={gridStyle}>
                <Button
                  className="whiteText"
                  style={buttonStyle}
                  variant={"outlined"}
                  onClick={() => setUserSelectionDialogIsOpen(true)}
                >
                  {"Seleccionar colaborador o grupo de referencia"}
                </Button>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-referenceEmployeeCode`}>{"Empleado de referencia"}</InputLabel>
                  <Select
                    disabled
                    value={formData.referenceEmployeeCode}
                    labelId={`label-referenceEmployeeCode`}
                    id={`select-referenceEmployeeCode`}
                    onChange={(e) => setFormValue(e.target.value, "referenceEmployeeCode")}
                    margin={"none"}
                  >
                    {
                      colaborators.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id={`label-referenceGroupCode`}>{"Grupo de referencia"}</InputLabel>
                  <Select
                    disabled
                    value={formData.referenceGroupCode}
                    labelId={`label-referenceGroupCode`}
                    id={`select-referenceGroupCode`}
                    onChange={(e) => setFormValue(e.target.value, "referenceGroupCode")}
                    margin={"none"}
                  >
                    {
                      groups.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
                <FileInput
                  acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.pdf', '.xlsx', '.xls', '.doc', '.docx']}
                  handleChange={(files) => setFormValue(files, 'attachment')}
                  id={`files`}
                />
                <FileList
                  files={formData.attachment}
                  handleClick={() => setFormValue(null, 'attachment')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              Boolean(formData?.attachment?.length && formData?.filePath?.length) &&
              <ButtonDialogAction isAccept onClick={() => previewFromURL(RESOURCES_ENDPOINT + formData.filePath)} text={"Ver adjunto"} />
            }
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
          </DialogActions>
        </div>
      </StyledDialog>
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={[formData.referenceEmployeeCode || formData.referenceGroupCode || ""]} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          users={[...groups.map((item) => ({ ...item, key: item.id })), ...colaborators]}
          title={'Seleccionar colaboradores'}
          selectedUsersTitle={'Seleccionados'}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          handleSubmit={(data) => setValueUserSelectionDialog(Boolean(data.length) ? data[0] : null)}
          singleSelection
        />
      }
    </>
  );
}

export default withStyles(styles)(CreateEditDialog);
