import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getEntryList,
  getConceptList,
  getImputationList,
  getDistributionList,
  getConceptsWithoutImputation
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteAccountingImputationsAction,
  duplicateAccountingImputationsAction,
  getAccountingImputationsAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useImputations = () => {

  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const entryList = useSelector(getEntryList);
  const conceptList = useSelector(getConceptList);
  const imputationList = useSelector(getImputationList);
  const distributionList = useSelector(getDistributionList);
  const conceptsWithoutImputation = useSelector(getConceptsWithoutImputation);

  useEffect(() => {
    dispatch(getAccountingImputationsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar la imputación?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length > 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro o ninguno', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents.length === 1 ? documents[0] : null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setDuplicateDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDelete = (document) => {
    dispatch(deleteAccountingImputationsAction({ internalCode: document.internalCode }));
  }

  const handleDuplicate = (dataToSend) => {
    dispatch(duplicateAccountingImputationsAction({ internalCode: selectedDocument.internalCode, ...dataToSend }))
      .then(res => {
        if (res?.status === 200) {
          handleCloseCreateEditDuplicateDialog();
        }
      })
  }

  const handleOpenViewDialog = () => {
    setViewDialogIsOpen(true);
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
  }

  const state = {
    duplicateDialogIsOpen,
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    viewDialogIsOpen,
  }

  const handlers = {
    handleDelete,
    handleDuplicate,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleOpenDuplicateDialog,
    handleCloseCreateEditDuplicateDialog,
    handleOpenViewDialog,
    handleCloseViewDialog,
  }

  return {
    data,
    entryList,
    conceptList,
    imputationList,
    distributionList,
    conceptsWithoutImputation,
    isLoading,
    state,
    handlers
  }
}

export default useImputations;