import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useImputations from "./useImputations";
import DuplicateDialog from "./dialogs/duplicateDialog/duplicateDialog";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import ViewGridDialog from "./dialogs/viewGridDialog";
import { FormatListBulleted } from "@icarius-icons";

const AccountingImputations = () => {

  const {
    data,
    entryList,
    conceptList,
    imputationList,
    distributionList,
    conceptsWithoutImputation,
    isLoading,
    state,
    handlers
  } = useImputations();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const viewButton = () => (
    <CustomIconButton
      title={'Ver conceptos sin imputación'}
      onClick={handlers.handleOpenViewDialog}
    >
      <FormatListBulleted />
    </CustomIconButton>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Imputaciones contables'}
      gridTitle={'Imputaciones contables'}
      columnDefPage={paths.accountingImputations}
      rowData={data}
      menuItems={[viewButton, createButton, modifyButton, duplicateButton, deleteButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          loading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
          entryList={entryList}
          conceptList={conceptList}
          imputationList={imputationList}
          distributionList={distributionList}
        />
      }
      {
        state.duplicateDialogIsOpen && !isLoading &&
        <DuplicateDialog
          open={state.duplicateDialogIsOpen}
          data={state.selectedDocument}
          handleConfirm={handlers.handleDuplicate}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
          entryList={entryList}
          conceptList={conceptList}
          imputationList={imputationList}
        />
      }
      {
        state.viewDialogIsOpen && !isLoading &&
        <ViewGridDialog
          open={state.viewDialogIsOpen}
          data={conceptsWithoutImputation}
          handleClose={handlers.handleCloseViewDialog}
        />
      }
    </CommonPage>
  )
}

export default AccountingImputations;