import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const UPDATE = NAME + "/UPDATE";
export const UPDATE_FULFILLED = NAME + "/UPDATE_FULFILLED";
export const UPDATE_REJECTED = NAME + "/UPDATE_REJECTED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";