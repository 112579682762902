import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsAdmin } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import { getLoading, getAccessRows, getAccessMap, getGroups, getUsers } from "../selectors";
import { getAccessSettingsAction } from "../actions";
import * as actionTypes from "../actionTypes";
import AccessMapGridDialog from "./accessMapGridDialog";
import DataItem from "./data/dataItem";
import Header from "./header";

const AccessSettings = () => {

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [searchManager, setSearchManager] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(null);

  const isLoading = useSelector(getLoading);
  const accessRows = useSelector(getAccessRows);
  const accessMap = useSelector(getAccessMap);
  const groups = useSelector(getGroups);
  const users = useSelector(getUsers);
  const isAdmin = useSelector(getIsAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccessSettingsAction());
    return () => dispatch({ type: actionTypes.RESET_STATE })
  }, [dispatch])

  const filteredRows = useMemo(() => {
    return Boolean(searchManager) ? accessRows.filter(row => row.userCode === searchManager.code) : accessRows;
  }, [searchManager, accessRows])

  const managersAndEmployers = useMemo(() => {
    return users ? users.filter(user => user.isManagerOrEmployer) : []
  }, [users])

  const getDataIsEmpty = () => {
    return !accessRows || accessRows.length === 0;
  }

  const getShouldDisabledIfThereAreAdmins = () => {
    const thereIsAnAdmin = accessRows?.some(item => item.isAdmin === 'Y') || false;

    if (thereIsAnAdmin && !isAdmin) return true;
    if (thereIsAnAdmin && isAdmin) return false;
    return false; // no hay admin
  }

  return (
    <ABMLayout
      title={getLocalizedString("accessSettingsTitle")}
      isLoading={isLoading}
      showNoData={getDataIsEmpty()}
      noDataTitle={getLocalizedString("noManagerFound")}
      noDataCreateTitle={"Nuevo acumulador"}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      <Header
        users={managersAndEmployers}
        selectedUser={searchManager}
        setUser={setSearchManager}
        showCreateButton={!getShouldDisabledIfThereAreAdmins() && !createIsOpen}
        createButtonOnClick={() => setCreateIsOpen(true)}
        handleOpenDialog={() => setDialogIsOpen(true)}
      />
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            data={null}
            users={users}
            groups={groups}
            rowsToUse={accessRows}
            closeNew={() => setCreateIsOpen(false)}
            getShouldDisabledIfThereAreAdmins={getShouldDisabledIfThereAreAdmins}
          />
        </Grid>
      }
      {
        filteredRows && filteredRows.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem
                data={item}
                users={users}
                groups={groups}
                rowsToUse={accessRows}
                getShouldDisabledIfThereAreAdmins={getShouldDisabledIfThereAreAdmins}
              />
            </Grid>
          )
        })
      }
      {
        dialogIsOpen &&
        <AccessMapGridDialog
          open={dialogIsOpen}
          data={accessMap}
          handleClose={() => setDialogIsOpen(false)}
        />
      }
    </ABMLayout>
  );
}

export default AccessSettings;
