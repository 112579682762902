import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyAlertActivationSettingsAction } from "../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import {
  getActivaList,
  getExemonList,
  getNotificationsList,
  getExediassList,
  getExediasmList,
  getExemmList,
  getGroupsList,
  getDestinatarioList,
  getRoleList,
  getTemplateList,
  getProcessList,
} from "../../selectors";
import { ARRAY_HOURS } from "@icarius-utils/date";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const gridStyle = { height: 75, padding: "0px 10px" };
const Form = (props) => {

  const { data, handleClose, alertType } = props;
  const dispatch = useDispatch();

  const activaList = useSelector(getActivaList);
  const exemomList = useSelector(getExemonList);
  const exediasmList = useSelector(getExediasmList);
  const exediassList = useSelector(getExediassList);
  const exemmList = useSelector(getExemmList);
  const groupsList = useSelector(getGroupsList);
  const destinatarioList = useSelector(getDestinatarioList);
  const notificationsList = useSelector(getNotificationsList);
  const roleList = useSelector(getRoleList);
  const templateList = useSelector(getTemplateList);
  const processList = useSelector(getProcessList);

  const modify = (formData) => {
    dispatch(modifyAlertActivationSettingsAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, alertType, modify, openValidationError);

  const requiresFE = formData['Momento de ejecución'] === 'FE';
  const requiresRF = formData['Momento de ejecución'] === 'RF';
  const requiresSD = formData['Momento de ejecución'] === 'SD';
  const requiresMD = formData['Momento de ejecución'] === 'MD';
  const requiresAM = formData['Momento de ejecución'] === 'AM';
  const requiresAF = formData['Momento de ejecución'] === 'AF';

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Tipo de Alerta o Aviso */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Tipo de Alerta o Aviso"}
            value={formData['Tipo de Alerta o Aviso']}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Nivel */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Nivel"}
            value={formData['Nivel']}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Nombre */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Nombre"}
            value={formData['Nombre']}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={alertType === 'U' ? 4 : 6} style={gridStyle}> {/* Activa */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-Activa`}>{"Activa"}</InputLabel>
            <Select
              value={formData.Activa}
              labelId={`label-Activa`}
              id={`select-Activa`}
              onChange={(e) => setFormValue(e.target.value, "Activa")}
              margin={"none"}
            >
              {
                activaList && activaList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          alertType === 'U' &&
          <>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Momento de ejecución */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Momento de ejecución`}>{"Momento de ejecución"}</InputLabel>
                <Select
                  value={formData['Momento de ejecución']}
                  labelId={`label-Momento de ejecución`}
                  id={`select-Momento de ejecución`}
                  onChange={(e) => setFormValue(e.target.value, "Momento de ejecución")}
                  margin={"none"}
                >
                  {
                    exemomList && exemomList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Hora de ejecución */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Momento de ejecución`}>{"Hora de ejecución"}</InputLabel>
                <Select
                  value={formData['Hora de ejecución']}
                  labelId={`label-Hora de ejecución`}
                  id={`select-Hora de ejecución`}
                  onChange={(e) => setFormValue(e.target.value, "Hora de ejecución")}
                  margin={"none"}
                >
                  {
                    ARRAY_HOURS.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </>
        }
        <Grid container item alignItems="center" xs={12} sm={alertType === 'U' ? 12 : 6} style={gridStyle}> {/* Notificación */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-Momento de ejecución`}>{"Notificación"}</InputLabel>
            <Select
              value={formData['Notificación']}
              labelId={`label-Notificación`}
              id={`select-Notificación`}
              onChange={(e) => setFormValue(e.target.value, "Notificación")}
              margin={"none"}
            >
              {
                notificationsList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          alertType === 'U' &&
          <>
            {
              requiresFE &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Fecha de ejecucion específica */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    required={requiresFE}
                    label={'Fecha de ejecucion específica'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData['Fecha de ejecucion específica']}
                    onChange={(e) => setFormValue(e, "Fecha de ejecucion específica")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              requiresRF &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Fecha de ejecucion desde */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    required={requiresRF}
                    fullWidth
                    label={'Fecha de ejecucion desde'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData['Fecha de ejecucion desde']}
                    onChange={(e) => setFormValue(e, "Fecha de ejecucion desde")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              requiresRF &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Fecha de ejecucion hasta */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    required={requiresRF}
                    fullWidth
                    label={'Fecha de ejecucion hasta'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formData['Fecha de ejecucion hasta']}
                    minDate={formData['Fecha de ejecucion desde']}
                    onChange={(e) => setFormValue(e, "Fecha de ejecucion hasta")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              requiresSD &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Día de la semana de ejecución */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={requiresSD} id={`label-Momento de ejecución`}>{"Día de la semana de ejecución"}</InputLabel>
                  <Select
                    value={formData['Día de la semana de ejecución']}
                    labelId={`label-Día de la semana de ejecución`}
                    id={`select-Día de la semana de ejecución`}
                    onChange={(e) => setFormValue(e.target.value, "Día de la semana de ejecución")}
                    margin={"none"}
                  >
                    {
                      exediassList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              requiresMD &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Día del mes de ejecución */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={requiresMD} id={`label-Momento de ejecución`}>{"Día del mes de ejecución"}</InputLabel>
                  <Select
                    value={formData['Día del mes de ejecución']}
                    labelId={`label-Día del mes de ejecución`}
                    id={`select-Día del mes de ejecución`}
                    onChange={(e) => setFormValue(e.target.value, "Día del mes de ejecución")}
                    margin={"none"}
                  >
                    {
                      exediasmList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              requiresAM &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Mes del año de ejecución */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={requiresAM} id={`label-Momento de ejecución`}>{"Mes del año de ejecución"}</InputLabel>
                  <Select
                    value={formData['Mes del año de ejecución']}
                    labelId={`label-Mes del año de ejecución`}
                    id={`select-Mes del año de ejecución`}
                    onChange={(e) => setFormValue(e.target.value, "Mes del año de ejecución")}
                    margin={"none"}
                  >
                    {
                      exemmList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
            {
              requiresAF &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Día y Mes del año de ejecución */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    required={requiresAF}
                    fullWidth
                    value={formData['Día y Mes del año de ejecución']}
                    onChange={(e) => setFormValue(e, "Día y Mes del año de ejecución")}
                    label={'Día y Mes del año de ejecución'}
                    variant="inline"
                    format="dd/MM"
                    margin="none"
                    invalidDateMessage=""
                    invalidLabel=""
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Destinatario */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Destinatario`}>{"Destinatario"}</InputLabel>
                <Select
                  value={formData.Destinatario}
                  labelId={`label-Destinatario`}
                  id={`select-Destinatario`}
                  onChange={(e) => setFormValue(e.target.value, "Destinatario")}
                  margin={"none"}
                >
                  {
                    destinatarioList && destinatarioList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Rol de Visualización  */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-Rol de Visualización`}>{"Rol de Visualización"}</InputLabel>
                <Select
                  value={formData['Rol de Visualización']}
                  labelId={`label-Rol de Visualización`}
                  id={`select-Rol de Visualización`}
                  onChange={(e) => setFormValue(e.target.value, "Rol de Visualización")}
                  margin={"none"}
                >
                  {
                    roleList && roleList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              ['CE', 'ME', 'EE'].includes(formData.Destinatario) &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Emails específicos */}
                <TextField
                  required={['CE', 'ME', 'EE'].includes(formData.Destinatario)}
                  fullWidth
                  margin={"none"}
                  label={"Emails específicos"}
                  onChange={(e) => setFormValue(e.target.value, "Emails específicos")}
                  value={formData['Emails específicos']}
                  inputProps={{ maxLength: 256 }}
                />
              </Grid>
            }
            {
              formData.Destinatario === 'GR' &&
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Grupos de destinatarios  */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={formData.Destinatario === 'GR'} id={`label-Grupos de destinatarios`}>{"Grupos de destinatarios"}</InputLabel>
                  <Select
                    multiple
                    value={formData['Grupos de destinatarios']}
                    labelId={`label-Grupos de destinatarios`}
                    id={`select-Grupos de destinatarios`}
                    onChange={(e) => setFormValue(e.target.value, "Grupos de destinatarios")}
                    margin={"none"}
                  >
                    {
                      groupsList && groupsList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </>
        }
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Código de Alerta o Aviso */}
          <TextField
            fullWidth
            disabled
            margin={"none"}
            label={"Código de Alerta o Aviso"}
            value={formData['Código de Alerta o Aviso']}
          />
        </Grid>
        {
          alertType === 'U' &&
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* template */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-Proceso especial a ejecutar`}>{"Proceso especial a ejecutar"}</InputLabel>
              <Select
                value={formData['Proceso especial a ejecutar']}
                labelId={`label-Proceso especial a ejecutar`}
                id={`select-Proceso especial a ejecutar`}
                onChange={(e) => setFormValue(e.target.value, "Proceso especial a ejecutar")}
                margin={"none"}
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Sin valor'}
                </MenuItem>
                {
                  processList && processList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid container item alignItems="center" xs={alertType === 'S' ? 6 : 12} style={gridStyle}> {/* template */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Plantilla WhatsApp`}>{"Plantilla WhatsApp"}</InputLabel>
            <Select
              value={formData['Plantilla WhatsApp']}
              labelId={`label-Plantilla WhatsApp`}
              id={`select-Plantilla WhatsApp`}
              onChange={(e) => setFormValue(e.target.value, "Plantilla WhatsApp")}
              margin={"none"}
            >
              <MenuItem className={"whiteText"} value={''}>
                {'Sin valor'}
              </MenuItem>
              {
                templateList && templateList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
