import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAlertSettingsAction, modifyAlertSettingsAction } from "../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  Button
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";
import { getNivelList, getClaseList } from "../../selectors";
import { AddIcon } from "@icarius-icons";
import { getAppColor } from "src/app/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };
const flexStyle = { height: 50, display: "flex", alignItems: "center", cursor: "pointer" };

const SelectorButton = ({ text, onClick }) => {

  const color = useSelector(getAppColor);

  return (
    <Button
      style={{ margin: 5 }}
      variant={"outlined"}
      disableRipple={true}
      onClick={onClick}>
      <AddIcon htmlColor={color} />
      {text}
    </Button>
  )
}

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const { data, handleClose, alertType, classes } = props;
  const dispatch = useDispatch();

  const nivelList = useSelector(getNivelList);
  const claseList = useSelector(getClaseList);

  const create = (formData) => {
    dispatch(createAlertSettingsAction(formData, alertType))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(modifyAlertSettingsAction(formData, alertType))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openSelectorValidationError = () => {
    dispatch(openSnackbarAction({ msg: "Selectores invalidos", severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, alertType, create, modify, openValidationError, openSelectorValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
      <Grid container item xs={12}>
        {
          !isCreate &&
          <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* Tipo de Alerta o Aviso */}
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={"Tipo de Alerta o Aviso"}
              onChange={(e) => setFormValue(e.target.value, "Tipo de Alerta o Aviso")}
              value={formData['Tipo de Alerta o Aviso']}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
        }
        <Grid container item alignItems="center" xs={12} sm={6} md={2} style={gridStyle}> {/* Nivel */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-Nivel`}>{"Nivel"}</InputLabel>
            <Select
              value={formData.Nivel}
              labelId={`label-Nivel`}
              id={`select-Nivel`}
              onChange={(e) => setFormValue(e.target.value, "Nivel")}
              margin={"none"}
            >
              {
                nivelList && nivelList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={isCreate ? 10 : 8} style={gridStyle}> {/* Nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre y asunto del correo"}
            value={formData.Nombre}
            onChange={(e) => setFormValue(e.target.value, "Nombre")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Texto de la Alerta o Aviso */}
          <TextField
            required
            fullWidth
            label="Texto de la Alerta o Aviso"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData['Texto de la Alerta o Aviso']}
            onChange={(e) => setFormValue(e.target.value, "Texto de la Alerta o Aviso")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Link o Enlace */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Link o Enlace"}
            value={formData['Link o Enlace']}
            onChange={(e) => setFormValue(e.target.value, "Link o Enlace")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Direccion en el Mapa */}
          <TextField
            fullWidth
            margin={"none"}
            label={"Direccion en el Mapa"}
            value={formData['Direccion en el Mapa']}
            onChange={(e) => setFormValue(e.target.value, "Direccion en el Mapa")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Clase */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-Clase`}>{"Clase"}</InputLabel>
            <Select
              value={formData['Clase de Alerta o Aviso']}
              labelId={`label-Clase`}
              id={`select-Clase`}
              onChange={(e) => setFormValue(e.target.value, "Clase de Alerta o Aviso")}
              margin={"none"}
            >
              {
                claseList && claseList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Origen de Alerta o Aviso */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Origen de Alerta o Aviso"}
            value={formData["Origen de Alerta o Aviso"]}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* SQL de ejecución */}
          <TextField
            required={alertType === 'U'}
            fullWidth
            label="SQL de ejecución"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData['SQL de ejecución']}
            onChange={(e) => setFormValue(e.target.value, "SQL de ejecución")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* SQL destinatarios */}
          <TextField
            fullWidth
            label="SQL para obtener destinatarios"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData['SQL destinatarios']}
            onChange={(e) => setFormValue(e.target.value, "SQL destinatarios")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* SQL de visualización */}
          <TextField
            fullWidth
            label="SQL de visualización"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData['SQL de visualización']}
            onChange={(e) => setFormValue(e.target.value, "SQL de visualización")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "0px 10px" }}> {/* Tipo selector por columna */}
          <TextField
            required={formData['SQL de visualización'].length > 0}
            fullWidth
            helperText={'Para cada columna de SQL de visualización, los tipos de selectores pueden ser: Lista,Fecha,Alfa,Num,Mon.'}
            margin={"none"}
            label={"Tipo selector por columna"}
            value={formData['Tipo selector por columna']}
            onChange={(e) => setFormValue(e.target.value, "Tipo selector por columna")}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ marginTop: 10, marginBottom: 20 }}>
          <Grid container item direction="column" sm={12}>
            <SelectorButton
              text={"Lista desplegable"}
              onClick={() => setFormValue(`${formData['Tipo selector por columna']},Lista`, "Tipo selector por columna")}
            />
            <SelectorButton
              text={"Fecha"}
              onClick={() => setFormValue(`${formData['Tipo selector por columna']},Fecha`, "Tipo selector por columna")}
            />
            <SelectorButton
              text={"Alfanumérico"}
              onClick={() => setFormValue(`${formData['Tipo selector por columna']},Alfa`, "Tipo selector por columna")}
            />
            <SelectorButton
              text={"Numérico entero"}
              onClick={() => setFormValue(`${formData['Tipo selector por columna']},Num`, "Tipo selector por columna")}
            />
            <SelectorButton
              text={"Numérico con decimales"}
              onClick={() => setFormValue(`${formData['Tipo selector por columna']},Mon`, "Tipo selector por columna")}
            />
          </Grid>
        </Grid>
        <Typography className="whiteText" style={{ fontSize: 14, marginTop: 20, padding: "0px 10px" }}>
          {'Si desea que en el cuerpo del correo se incluyan datos del SQL de visualización, marque "Graba referencia" e indique los números de columna separados por coma (Ej. 1,2,5)'}
        </Typography>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", position: "relative", top: "10px" }}>
            <div style={flexStyle} onClick={() => setFormValue(!Boolean(formData['Graba referencia']), "Graba referencia")}> {/* Graba referencia */}
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {"Graba referencia"}
              </Typography>
              <Checkbox checked={formData['Graba referencia']} />
            </div>
          </div>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Nros columnas referencia */}
          <TextField
            required={Boolean(formData['Graba referencia']) && alertType === 'U'}
            fullWidth
            margin={"none"}
            label={"Nros columnas referencia"}
            value={formData['Nros columnas referencia']}
            onChange={(e) => setFormValue(e.target.value, "Nros columnas referencia")}
            inputProps={{ maxLength: 254 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Texto para cuerpo del email */}
          <TextField
            required={alertType === 'U'}
            fullWidth
            label="Texto para el cuerpo de la alerta y correo"
            variant="outlined"
            multiline
            rows={25}
            margin={"none"}
            value={formData['Texto para cuerpo del email']}
            onChange={(e) => setFormValue(e.target.value, "Texto para cuerpo del email")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Form);
