import { useState } from "react";
import * as yup from 'yup';
import { createDateFromDDMMYYYY, formatDateYYYYMMDD, getNextDay } from "@icarius-utils/date";

const useHandleForm = (data, employeeCode, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'employeeCode') {
        return isCreate ? employeeCode : data[fieldName];
      }

      if (fieldName === 'status') {
        return isCreate ? 'P' : data[fieldName];
      }

      if (fieldName === 'reminderDate') {
        return isCreate ? null : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'creationDate') {
        return isCreate ? null : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'attachment') {
        return isCreate ? null : (data.attachment ? [{ name: data.attachment }] : null);
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'employeeCode',
      'creationDate',
      'type',
      'description',
      'status',
      'reminderDate',
      'attachment',
      'referenceEmployeeCode',
      'referenceGroupCode',
      'filePath',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const {
    reminderDate,
    creationDate,
    attachment,
  } = formData;

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'creationDate': yup.date().required(),
      'type': yup.string().max(8).required(),
      'description': yup.string().required(),
      'status': yup.string().max(1),
      'reminderDate': yup.date(),
      'referenceEmployeeCode': yup.string().max(20),
      'referenceGroupCode': yup.string().max(20),
    });
    return await schema.isValid(formData).then((valid) => valid);
  }

  const datesAreValid = () => {
    const newStartDate = creationDate.setHours(0, 0, 0, 0).valueOf();
    const newEndDate = reminderDate.setHours(0, 0, 0, 0).valueOf();
    return newStartDate <= newEndDate;
  }

  const submit = async () => {
    if (await dataIsValid() && datesAreValid()) {
      let dataToSend = {
        ...formData,
        employeeCode,
        creationDate: formatDateYYYYMMDD(creationDate),
        reminderDate: formatDateYYYYMMDD(reminderDate),
      };

      const hasRealFile = Boolean(attachment?.[0]?.size); // si no tiene size, esta seteado el mockeado con el attachment que vino de back
      dataToSend.FILES = hasRealFile ? attachment[0] : null;

      if (!isCreate) {
        // si tenia archivo, y ahora no tengo, es porque borre en local y hay que borrar en back
        dataToSend.deleteFile = Boolean(data.attachment && attachment === null);
        dataToSend.internalCode = data.internalCode;
      }

      delete dataToSend.attachment;

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'creationDate') {
      setFormData({
        ...formData,
        [fieldName]: value,
        "reminderDate": getNextDay(value),
      });
      return;
    }

    if (fieldName === "referenceEmployeeCode") {
      setFormData({
        ...formData,
        "referenceEmployeeCode": value,
        "referenceGroupCode": "",
      });
      return;
    }

    if (fieldName === "referenceGroupCode") {
      setFormData({
        ...formData,
        "referenceGroupCode": value,
        "referenceEmployeeCode": "",
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
