import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading;
export const getRows = store => store[NAME].rows;

export const getNivelList = store => store[NAME].nivelList;
export const getClaseList = store => store[NAME].claseList;
export const getSelcolList = store => store[NAME].selcolList;
export const getDestinatarioList = store => store[NAME].destinatarioList;
export const getGroupsList = store => store[NAME].groupsList;

export const getActivationRow = store => store[NAME].activationRow;
export const getActivaList = store => store[NAME].activaList;
export const getExemonList = store => store[NAME].exemomList;
export const getExediassList = store => store[NAME].exediassList;
export const getExediasmList = store => store[NAME].exediasmList;
export const getExemmList = store => store[NAME].exemmList;
export const getNotificationsList = store => store[NAME].notificationsList;
export const getRoleList = store => store[NAME].roleList;
export const getTemplateList = store => store[NAME].templateList;
export const getProcessList = store => store[NAME].processList;