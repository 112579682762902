import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.userAlertsSettings],
        config: {
            headerName: "Tipo de Alerta o Aviso",
            field: "Tipo de Alerta o Aviso",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Nombre",
            field: "Nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Nivel",
            field: "nivelString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Activa",
            field: "activaString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Momento de ejecución",
            field: "executionMoment",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Hora de ejecución",
            field: "executionTime",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Texto de la Alerta o Aviso",
            field: "Texto de la Alerta o Aviso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Clase",
            field: "claseString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Texto para cuerpo del mensaje",
            field: "Texto para cuerpo del email",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Notificación",
            field: "notificacionString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Destinatario",
            field: "destString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Plantilla de WhatsApp",
            field: "templateString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userAlertsSettings],
        config:
        {
            headerName: "Proceso especial a ejecutar",
            field: "processToExecute",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config: {
            headerName: "Tipo de Alerta o Aviso",
            field: "Tipo de Alerta o Aviso",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Nombre",
            field: "Nombre",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Nivel",
            field: "nivelString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Activa",
            field: "activaString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Texto de la Alerta o Aviso",
            field: "Texto de la Alerta o Aviso",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Clase",
            field: "claseString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Texto para cuerpo del mensaje",
            field: "Texto para cuerpo del email",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Notificación",
            field: "notificacionString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.systemAlertsSettings],
        config:
        {
            headerName: "Plantilla de WhatsApp",
            field: "templateString",
            filter: "agSetColumnFilter",
        }
    },
]