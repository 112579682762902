import React, { useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import Schedule from "./schedule";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { calendarActionsTypeColors } from "@icarius-utils/colors";
import useActionsSchedule from "./useActionsSchedule";
import useGenericSchedule from "./useGenericSchedule";

const typesStrings = {
  done: 'Realizada',
  pending: 'Pendiente',
}

const Calendar = (props) => {
  const {
    data,
    colaborators,
    groups,
    handleClose,
    isLoading,
  } = props;

  const [view, setView] = useState('week');

  const {
    staff,
    shifts,
    handleSubmit,
  } = useActionsSchedule(data, colaborators, groups);

  const {
    scheduleFunctions,
    scheduleData,
  } = useGenericSchedule(handleSubmit);

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      type={'goBack'}
      onClick={handleClose}
    />
  )

  const changeViewItem = () => (
    <FormControl style={{ width: '200px', marginLeft: 10, marginRight: 10 }}>
      <InputLabel id={`label-view`}>{'Vista'}</InputLabel>
      <Select
        value={view}
        onChange={(e) => setView(e.target.value)}
        labelId={`label-view`}
        id={`select-view`}
        margin={"none"}
      >
        {
          [
            {
              key: 'week',
              value: 'Semana',
            },
            {
              key: 'month',
              value: 'Mes',
            },
            {
              key: 'year',
              value: 'Año',
            },
          ].map((item) => (
            <MenuItem
              className={"whiteText"}
              key={item.key}
              value={item.key}
            >
              {item.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  const typesSelectItem = () => (
    <FormControl style={{ width: '200px', marginLeft: 10, marginRight: 10 }}>
      <InputLabel id={`label`}>{'Tipos'}</InputLabel>
      <Select
        value={''}
        labelId={`label`}
        id={`select`}
        margin={"none"}
      >
        {
          ['pending', 'done'].map((item) => (
            <MenuItem
              className={"whiteText"}
              key={item}
              value={item}
            >
              <div style={{ marginRight: 10, height: 10, width: 10, borderRadius: '50%', background: calendarActionsTypeColors[item] }} />
              {typesStrings[item]}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )

  return (
    <CommonPage
      title={'Mis acciones planificadas'}
      isLoading={isLoading}
      menuItems={[goBackButton, changeViewItem, typesSelectItem]}
      isNotGridPage
    >
      <Schedule
        staff={staff}
        shifts={shifts}
        scheduleFunctions={scheduleFunctions}
        scheduleData={scheduleData}
        view={view}
      />
    </CommonPage>
  )
}

export default Calendar;