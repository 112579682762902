import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdvancesSettingsAction } from "../actions";
import { getLoading, getPermissions, getTypes, getEmployeesList } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import Item from "./publication/item";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";

const AdvancesSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getPermissions);
  const types = useSelector(getTypes);
  const employeesList = useSelector(getEmployeesList);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAdvancesSettingsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }
  
  const handleGoBack = () => {
    history.push(paths.requestsSettings);
  }

  return (
    <ABMLayout
      title={getLocalizedString("advancesSettings")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newAdvance")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={getLocalizedString("noAdvances")}
      noDataCreateTitle={getLocalizedString("newAdvance")}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      <Grid container item xs={12} direction="row">
        {
          createIsOpen &&
          <Grid container item xs={12} style={{ margin: "10px 0px" }}>
            <Item
              color={color}
              data={null}
              types={types}
              employeesList={employeesList}
              closeCreate={() => setCreateIsOpen(false)}
            />
          </Grid>
        }
        {
          data && data.map((item) => {
            return (
              <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.internalCode}>
                <Item data={item} types={types} color={color} employeesList={employeesList} />
              </Grid>
            )
          })
        }
      </Grid>
    </ABMLayout>
  )
}

export default AdvancesSettings;