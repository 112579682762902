import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, type, createCallback, modifyCallback, invalidDataCallback, openSelectorValidationError) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "Graba referencia") {
        return isCreate ? false : Boolean(data[fieldName] === "Y");
      }

      if (fieldName === "Clase de Alerta o Aviso") {
        return isCreate ? 'I' : data[fieldName];
      }

      if (fieldName === "Tipo selector por columna") {
        return isCreate ? "" : data[fieldName].join(",");
      }

      if (fieldName === "Origen de Alerta o Aviso") {
        return type === 'S' ? 'Sistema' : "Usuario";
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'Tipo de Alerta o Aviso',
      'Nivel',
      'Nombre',
      'Texto de la Alerta o Aviso',
      'Link o Enlace',
      'Graba referencia',
      'Tipo selector por columna',
      'Clase de Alerta o Aviso',
      'Direccion en el Mapa',
      'Nros columnas referencia',
      'Origen de Alerta o Aviso',
      'SQL de ejecución',
      'SQL destinatarios',
      'SQL de visualización',
      'Texto para cuerpo del email',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const schema = yup.object().shape({
      'Nivel': yup.string().required(),
      'Nombre': yup.string().max(254).required(),
      'Texto de la Alerta o Aviso': yup.string().required(),
      'Link o Enlace': yup.string().max(254),
      'Graba referencia': yup.bool().required(),
      'Tipo selector por columna': yup.string()
        .when('SQL de visualización', {
          is: (sqlVisualization) => sqlVisualization.length > 0,
          then: yup.string().required()
        }),
      'Clase de Alerta o Aviso': yup.string().required(),
      'Direccion en el Mapa': yup.string(),
      'Nros columnas referencia': yup.string()
        .when(['Graba referencia', 'Origen de Alerta o Aviso'], {
          is: (reference, origin) => Boolean(reference) && origin === 'Usuario',
          then: yup.string().required()
        }),
      'SQL de ejecución': yup.string()
        .when('Origen de Alerta o Aviso', {
          is: (type) => type === 'Usuario',
          then: yup.string().required()
        }),
      'SQL destinatarios': yup.string(),
      'SQL de visualización': yup.string(),
      'Texto para cuerpo del email': yup.string()
        .when('Origen de Alerta o Aviso', {
          is: (type) => type === 'Usuario',
          then: yup.string().required()
        }),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const selectorsAreValid = () => {
    if (formData['Tipo selector por columna'].length < 1) return true;
    const selectors = formData['Tipo selector por columna'].split(",");
    const validSelectors = [
      "Lista",
      "Fecha",
      "Alfa",
      "Num",
      "Mon",
    ];

    return selectors.every(selector => validSelectors.includes(selector));
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (selectorsAreValid()) {
        const formDataToSend = {
          ...formData,
          'Origen de Alerta o Aviso': type,
        };

        formDataToSend["Graba referencia"] = formDataToSend["Graba referencia"] ? "Y" : "N";
        if (formDataToSend["Tipo selector por columna"].length === 0) {
          formDataToSend["Tipo selector por columna"] = [];
        } else {
          formDataToSend["Tipo selector por columna"] = formDataToSend["Tipo selector por columna"].split(",");
        }

        isCreate ? createCallback(formDataToSend) : modifyCallback(formDataToSend);
        return true;
      }
      openSelectorValidationError();
      return false;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "Tipo selector por columna") {
      if (value.length === 0) return "";
      if (value.charAt(0) === ",") {
        return value.substring(1);
      }
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
