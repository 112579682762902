import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, rowsToUse, groups, getShouldDisabledIfThereAreAdmins) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {
      if (fieldName === "active") {
        return isCreate ? true : Boolean(data[fieldName] === "Y");
      }
      if (fieldName === "employer") {
        return isCreate ? false : Boolean(data[fieldName] === "Y");
      }
      if (fieldName === "isAdmin") {
        return isCreate ? false : Boolean(data[fieldName] === "Y");
      }
      if (fieldName === "requestRecipient") {
        return isCreate ? true : Boolean(data[fieldName] === "Y");
      }
      if (fieldName === "receiveActions") {
        return isCreate ? true : Boolean(data[fieldName] === "Y");
      }

      if (fieldName === "groups") {
        return isCreate ? [] : data[fieldName];
      }
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "email",
      "active",
      "employer",
      "groups",
      "isAdmin",
      "requestRecipient",
      "receiveActions",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    const { name, } = formData;

    return !((!name || name === ""));
  }

  const createAllGroupsArray = () => {
    return groups.map(group => group.key);
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        email: formData.email,
        active: formData.active ? 'Y' : 'N',
        employer: formData.employer ? 'Y' : 'N',
        isAdmin: formData.isAdmin ? 'Y' : 'N',
        requestRecipient: formData.requestRecipient ? 'Y' : 'N',
        receiveActions: formData.receiveActions ? 'Y' : 'N',
        groupsSelected: formData.groups.length === 0 ? createAllGroupsArray().join(",") : formData.groups.join(","),
      };

      if (!isCreate) {
        dataToSend.code = formData.code;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const checkIfNameCanBeUsed = (value) => {
    const arrToCompare = rowsToUse.filter(row => row.userCode === value.code);
    return arrToCompare.length !== 2;
  }

  const checkIfCanBeEmployer = (value) => {
    const newEmployer = value ? "Y" : "N";
    const arrToCompare = rowsToUse.filter(row => row.userCode === formData.code && row.employer === newEmployer);
    return arrToCompare.length !== 1;
  }

  const setFormValue = (value, fieldName) => {
    if (getShouldDisabledIfThereAreAdmins()) return;

    if (fieldName === "name") {
      if (Boolean(value)) {
        if (!checkIfNameCanBeUsed(value)) {
          dispatch(openSnackbarAction({ msg: getLocalizedString("errorEmployeeInUseAlready"), severity: "error" }));
        } else {
          setFormData({
            ...formData,
            name: value.name,
            email: value.email,
            code: value.code
          })
        }
      }
      return;
    }

    if (fieldName === "employer") {
      if (!checkIfCanBeEmployer(value)) {
        dispatch(openSnackbarAction({ msg: getLocalizedString("errorTwoEmployersAlready"), severity: "error" }));
      } else {
        if (value === false) { // si no es employer, sacarle el admin y el receiveActions
          setFormData({
            ...formData,
            [fieldName]: value,
            isAdmin: false,
            receiveActions: true,
          })
          return;
        }
        setFormData({
          ...formData,
          [fieldName]: value
        })
      }
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
