import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid, getIsAdmin } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { useLocation } from "react-router-dom";
import CreateEditFormContainer from "./createEditForm/formContainer";
import ActivationFormContainer from "./activationForm/formContainer";
import ConfirmationDialog from "./dialogs/confirmationDialog";
import DuplicateDialog from "./dialogs/duplicateDialog";
import useABM from "./useABM";
import { getAlertsSettingsAction } from "../actions";
import { getRows, getLoading } from "../selectors";
import { CLEAR_STATE } from "../actionTypes";

const AlertsDefinition = ({ history }) => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const isAdmin = useSelector(getIsAdmin);

  const location = useLocation();
  const alertType = location.pathname === paths.userAlertsSettings ? "U" : "S";

  useEffect(() => {
    dispatch(getAlertsSettingsAction(alertType));
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch, alertType])

  const {
    deleteDialogIsOpen,
    createEditDialogIsOpen,
    duplicateDialogIsOpen,
    activationIsOpen,
    selectedItem,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenActivationPage,
    handleCloseActivationPage,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleDuplicate
  } = useABM();

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.alertsSettings)}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const duplicateItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />

  const handleRowClick = (row) => {
    handleOpenActivationPage(row.data['Tipo de Alerta o Aviso']);
  }

  const customItems = [goBackItem, addItem, editItem, deleteItem, duplicateItem];
  const customItemsOnlyGoBack = [goBackItem];

  const helpItems = [
    <MenuItemWithIcon key={getLocalizedString("alerts")} value={"https://www.youtube.com/embed/Am30JRLMlW4"} text={getLocalizedString("alerts")} type={"video"} />
  ]

  return (
    <>
      {
        activationIsOpen &&
        <ActivationFormContainer
          isLoading={isLoading}
          alertType={alertType}
          handleClose={handleCloseActivationPage}
        />
      }
      {
        createEditDialogIsOpen &&
        <CreateEditFormContainer
          isLoading={isLoading}
          data={selectedItem}
          alertType={alertType}
          handleClose={handleCloseEditDialog}
        />
      }
      {
        !(createEditDialogIsOpen || activationIsOpen) &&
        <CommonPage
          columnDefPage={location.pathname}
          title={`Alertas/avisos del ${alertType === "S" ? 'sistema' : 'usuario'}`}
          gridTitle={`Alertas/avisos del ${alertType === "S" ? 'sistema' : 'usuario'}`}
          menuItems={!isAdmin ? customItemsOnlyGoBack : customItems}
          helpItems={helpItems}
          rowData={data}
          isLoading={isExportinGrid || isLoading}
          handleRowClick={handleRowClick}
          hasExpand
          hasHelp
        >
          {
            deleteDialogIsOpen && !isLoading &&
            <ConfirmationDialog
              open={deleteDialogIsOpen}
              title={getLocalizedString("atention")}
              text={"¿Está seguro que desea eliminar la alerta/aviso?"}
              handleConfirm={handleDelete}
              handleClose={handleCloseDeleteDialog}
            />
          }
          {
            duplicateDialogIsOpen && !isLoading &&
            <DuplicateDialog
              open={duplicateDialogIsOpen}
              data={selectedItem}
              alertType={alertType}
              handleConfirm={handleDuplicate}
              handleClose={handleCloseDuplicateDialog}
            />
          }
        </CommonPage>
      }
    </>
  );
}

export default AlertsDefinition;
