import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, TextField } from "@material-ui/core";
import FabButton from "@icarius-common/fabButton";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import StyledHelpMenu from "@icarius-common/styledMenu";
import ListIcon from '@material-ui/icons/List';

const Header = (props) => {

  const {
    users,
    selectedUser,
    setUser,
    createButtonOnClick,
    showCreateButton,
    handleOpenDialog,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickHelpMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container style={{ marginTop: 10 }} justify={showCreateButton ? "space-between" : "flex-end"}>
      {
        showCreateButton &&
        <FabButton onClick={createButtonOnClick} title={getLocalizedString("newManager")} />
      }
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
        <div style={{ marginRight: 20 }}>
          {
            users ?
              <Autocomplete
                options={users}
                value={selectedUser}
                onChange={(event, newUser) => {
                  setUser(newUser);
                }}
                clearOnBlur
                clearText={getLocalizedString("clear")}
                noOptionsText={''}
                getOptionLabel={(user) => user.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label={getLocalizedString("filterByManager")} />}
              />
              : null
          }
        </div>
        <CustomIconButton
          title={'Mapa de acceso'}
          onClick={handleOpenDialog}
        >
          <ListIcon />
        </CustomIconButton>
        <CustomIconButton
          title={getLocalizedString("help")}
          onClick={handleClickHelpMenu}
          type={"help"}
        />
        <StyledHelpMenu
          anchorEl={anchorEl}
          isOpen={Boolean(anchorEl)}
          onClose={handleCloseHelpMenu}>
          <MenuItemWithIcon
            value={"https://www.youtube.com/embed/AS8LUsd6W9w"}
            text={getLocalizedString("accessByGroups")}
            type={"video"}
          />
          <MenuItemWithIcon
            value={"/resources/ICARIUS - Accesos segmentado por GRUPOS.pdf"}
            text={getLocalizedString("accessosSegmentadoPorGrupos")}
            type={"file"}
          />
        </StyledHelpMenu>
      </div>
    </Grid>
  );
}

export default Header;
