import * as actionTypes from "./actionTypes";
import { UPDATE_ALERTS_BADGE } from "src/app/actionTypes";
import {
  getAlertsAPI,
  modifyAlertStatusAPI,
  deleteAlertsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getAlertsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAlertsAPI();

    let alerts = response.data && response.data.alerts;
    let types = response.data && response.data.classes;
    let locale = response.data && response.data.currency_localization;
    let dateFormat = response.data && response.data.date_format;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        alerts,
        types,
        locale,
        dateFormat,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const modifyAlertStatusAction = (code) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE });

    let response = await modifyAlertStatusAPI({ code });
    let status = response.data && response.data.status;
    let unread = response.data && response.data.unread;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: { code }
    });

    dispatch({
      type: UPDATE_ALERTS_BADGE,
      payload: { quantity: Number(unread) },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED });
  }
};

export const deleteAlertsAction = (codes) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE });

    let response = await deleteAlertsAPI({ codes });
    let status = response.data && response.data.status;
    let unread = response.data && response.data.unread;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: codes,
    });



    dispatch({
      type: UPDATE_ALERTS_BADGE,
      payload: { quantity: Number(unread) },
    });

    dispatch(openSnackbarAction({ msg: 'Alertas eliminadas éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED });
    dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    return e.response;
  }
};