import React, { useState } from 'react';
import { formatDate } from '@mobiscroll/react';
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getAppColor } from 'src/app/selectors';
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";

const onMediaFallback = event => event.target.src = DefaultImage;

const useGenericSchedule = (handleSubmit) => {

  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [headerText, setHeader] = useState('');
  const [newCreationDate, setNewCreationDate] = useState(null)
  const [newReminderDate, setNewRemiderDate] = useState(null)
  const [selectedShift, setSelectedShift] = useState(null);

  const color = useSelector(getAppColor);

  const saveEvent = () => {
    if (!newCreationDate || !newReminderDate) {
      return;
    }

    handleSubmit(selectedShift.id, (newCreationDate || selectedShift.start), (newReminderDate || selectedShift.reminderDate));
    setPopUpIsOpen(false);
  };

  const onEventClick = (args) => {
    const event = args.event;

    setHeader(
      `<div>Modificar fechas</div>
        <div style="font-size: 16px">${event.title}</div>
        <div class="employee-shifts-day"> 
          Fecha creación: ${formatDate('DDDD', new Date(event.start))}, ${formatDate('DD MMMM YYYY', new Date(event.start))}
          <br/>
          Fecha recordatoria: ${formatDate('DDDD', new Date(event.reminderDate))}, ${formatDate('DD MMMM YYYY', new Date(event.reminderDate))}
      </div>`
    );

    setSelectedShift(event);
    setNewCreationDate(event.start);
    setNewRemiderDate(event.reminderDate);
    setPopUpIsOpen(true);
  };

  const onClose = () => {
    setPopUpIsOpen(false);
  };

  const getPopupButtons = () => {
    return [
      'cancel',
      {
        handler: () => {
          saveEvent();
        },
        keyCode: 'enter',
        text: 'Guardar',
        cssClass: 'mbsc-popup-button-primary',
      }
    ];
  };

  const renderMyResource = (resource) => {
    if (!resource.value) {
      return <div className="employee-shifts-cont" />
    }

    return (
      <Tooltip title={resource.title || 'Sin turno asignado'}>
        <div className="employee-shifts-cont">
          <div
            className="employee-shifts-name"
            style={{
              minHeight: 34,
              display: 'flex',
              alignItems: 'center',
              marginLeft: 5,
            }}
          >
            {resource.value}
          </div>
          {
            resource.img ?
              <img
                className="employee-shifts-avatar"
                src={IMAGES_ENDPOINT + resource.img}
                alt="Avatar"
                style={{ width: 40, height: 40, borderRadius: '50%' }}
                onError={onMediaFallback}
              />
              :
              <div
                className="employee-shifts-avatar"
                style={{ width: 40, height: 40, background: color, borderRadius: '50%' }}
              />
          }
        </div>
      </Tooltip>
    );
  }

  const handleDateChange = (args, name) => {
    if (name === 'reminder') {
      setNewRemiderDate(args);
      return;
    }

    setNewCreationDate(args);
  };

  // es el popover que sale al hacer clic en un item con la view de calendar
  const renderEvent = (args) => {
    return (
      <div
        style={{
          background: args.color,
          borderRadius: 10,
          padding: 10,
          margin: -10,
          fontSize: '0.75em',
          fontWeight: 600,
        }}
      >
        {args.tooltip}
      </div>
    )
  }

  const scheduleFunctions = {
    onEventClick,
    getPopupButtons,
    onClose,
    renderEvent,
    renderMyResource,
    handleDateChange,
  };

  const scheduleData = {
    popUpIsOpen,
    headerText,
    selectedShift,
    newCreationDate,
    newReminderDate,
  };

  return {
    scheduleFunctions,
    scheduleData,
  }
}

export default useGenericSchedule;