import React from "react";
import paths from "@icarius-localization/paths";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  HealthInstitutionIcon,
  RetirementIcon,
} from "@icarius-icons/";

const AffiliationInstitutionDefinition = () => {

  const data = [
    {
      name: "Previsión",
      icon: <RetirementIcon />,
      path: paths.retirementDefinition,
    },
    {
      name: "Salud",
      icon: <HealthInstitutionIcon />,
      path: paths.healthDefinition,
    },
  ];

  return (
    <ScreenSelector
      title={"Definir instituciones de afiliación"}
      data={data}
    />
  );
}

export default AffiliationInstitutionDefinition;