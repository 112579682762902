import React from "react";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import useActions from "./useActions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./dialogs/createEditDialog";
import ExternalFileRenderer from "@icarius-table/renderersAndFilters/externalFileRenderer";
import Calendar from "./calendar";

const Actions = () => {

  const {
    isLoading,
    data,
    employeeCode,
    groups,
    colaborators,
    typeList,
    statusList,
    state,
    handlers
  } = useActions();

  const calendarItem = () => (
    <CustomIconButton
      title={"Ver calendario"}
      onClick={() => handlers.handleOpenCalendar()}
      type={"calendar"}
    />
  )

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  return (
    <>
      <div style={{ display: state.calendarIsOpen ? "block" : "none" }}>
        <Calendar
          data={data}
          colaborators={colaborators}
          groups={groups}
          handleClose={handlers.handleCloseCalendar}
          isLoading={isLoading}
        />
      </div>
      <div style={{ display: !state.calendarIsOpen ? "block" : "none" }}>
        <CommonPage
          isLoading={isLoading}
          title={'Mis acciones y recordatorios'}
          gridTitle={'Mis acciones y recordatorios'}
          columnDefPage={paths.actions}
          rowData={data}
          menuItems={[createButton, modifyButton, deleteButton, calendarItem]}
          hasExpand
          hasHelp
          frameworkComponents={{ ExternalFileRenderer }}
        >
          {
            (state.createDialogIsOpen || state.editDialogIsOpen) &&
            <CreateEditDialog
              open={(state.createDialogIsOpen || state.editDialogIsOpen)}
              loading={isLoading}
              data={state.selectedDocument}
              handleClose={handlers.handleCloseCreateEditDuplicateDialog}
              typeList={typeList}
              statusList={statusList}
              employeeCode={employeeCode}
              colaborators={colaborators}
              groups={groups}
            />
          }
        </CommonPage>
      </div>
    </>
  )
}

export default Actions;