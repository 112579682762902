import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccumulatorsAction } from "../actions";
import { getLoading, getData, getMonths } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import paths from "@icarius-localization/paths";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const AccumulatorSettings = ({ history }) => {

  const [ createIsOpen, setCreateIsOpen ] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);  
  const months = useSelector(getMonths);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAccumulatorsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {    
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.conceptsAndFormulasScreenSelector);
  }

  return (
    <ABMLayout
      title={"Definición de acumuladores"}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nuevo acumulador"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron acumuladores"}
      noDataCreateTitle={"Nuevo acumulador"}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            months={months}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem data={item} months={months} color={color} />
            </Grid>
          )
        })
      }      
    </ABMLayout>
  )
}

export default AccumulatorSettings;