import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  types: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.alerts,
        types: action.payload.types,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FULFILLED: {
      let newRows = [...state.data];
      const alertIndex = newRows.findIndex((item) => item.code === action.payload.code);

      if (alertIndex !== -1) {
        newRows[alertIndex].isRead = !newRows[alertIndex].isRead;
      }

      return {
        ...state,
        isLoading: false,
        data: newRows,
      };
    }
    case actionTypes.UPDATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED: {
      if (!action.payload.length) { // si no hay payload (codes), borrar los leidos
        const unreadAlerts = state.data.filter((alert) => !alert.isRead);

        return {
          ...state,
          isLoading: false,
          data: unreadAlerts
        };
      }

      const filteredData = state.data.filter((alert) => !action.payload.includes(alert.code));
      return {
        ...state,
        isLoading: false,
        data: filteredData,
      };
    }
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: true };

    default:
      return state;
  }
}
